.work-feature-holder:after{width:100%;height:100%;content:"";position: absolute;top:-370px;right:0;z-index:1;background-image:url('../../../assets//images/icon-rectangle.svg');background-repeat: no-repeat;background-position: 100% 0;}
.work-feature-holder .work_sectionTitle{color:#0D0F10;padding:0 50px ;font-size:42px;line-height:60px;font-weight:600}
.work-feature-holder .works .video-holder{position: relative;z-index: 3;border-radius:10px;overflow: hidden;}
.work-feature-holder .work_sectionTitle p{margin:0 0 20px;}
.home-blogs-section{padding:0 0 130px;}
@media (max-width:1899px){
	.work-feature-holder .work_sectionTitle{font-size:34px;line-height:48px;font-weight:500;}
	.work-feature-holder .work_sectionTitle h2{font-size:42px;line-height:45px;margin:0 0 15px;}
	.home-blogs-section{padding:0 0 100px;}
}
@media (max-width:1599px){
	.work-feature-holder:after{top:-240px;}
	.work-feature-holder .work_sectionTitle{font-size:30px;line-height:48px;}
	.home-blogs-section{padding:0 0 80px;}
}
@media (max-width:1399px){
	.work-feature-holder .work_sectionTitle h2{font-size:36px;line-height:40px;}
	.work-feature-holder .work_sectionTitle{font-size:26px;line-height:42px;}
	.home-blogs-section{padding:0 0 50px;}
}
@media (max-width:991px){
	.work-feature-holder .work_sectionTitle h2{font-size:32px;line-height:35px;}
	.work-feature-holder .work_sectionTitle{padding:0;font-size:22px;line-height:30px;font-weight:400;}
	.work-feature-holder:after {top:-150px}
	.home-blogs-section{padding:0 0 30px;}
}
@media(max-width:767px){
	.work-feature-holder .work_sectionTitle h2{font-size:24px;line-height:28px;}
	.work-feature-holder .work_sectionTitle{font-size:18px;line-height:30px;}
}
@media(max-width:575px){
	.work-feature-holder:after{top:-50px;}
}