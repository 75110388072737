#Community-questions .Community-questions{padding: 10px; border-radius: 10px;}
.Community-questions h3{font-size: 28px; line-height: 32px; font-weight: 700; color: #505050; margin-bottom: 20px;}
.community-answer-modal .modal-header{padding: 0; border: none;}
/* .community-answer-modal .modal-content{min-width:  700px;} */
.community-answer-modal .modal-content .modal-title{margin: 0;}
.community-answer-modal .modal-body{padding:  10px 0 20px 0;}
.Community-answer-form textarea{resize: none; min-height: 200px; border: none;}
.Community-answer-form textarea:focus{border: none;}
.community-answer-modal .modal-footer{justify-content: flex-start;}
.community-modal-wrapper .community-answer-btn{padding: 8px 15px; margin-top: 0;}
.Community-choose-img{width: 30px; height: 30px;}
.Community-choose-img .form-control{ height: 30px; width: 30px;opacity: 0; font-size: 0; line-height: 0; letter-spacing: 0; position: absolute; left: 0; top: 0; z-index: 3; padding: 0; margin: 0;}
.Community-choose-img span{width: 30px; height: 30px; position: absolute; left: 0; top: 0; z-index: 0; display: block; color: #FD6F21;}
.answer-modal-footer button{margin-top: 0; padding: 8px 15px; min-width: auto;}
.answer-follow-btn{padding: 0; border: none; color: #FD6F21;} 
.community-modal-wrapper{display: flex; align-items: center;}
.community-modal-wrapper button{margin-right: 20px;}
.community-modal-wrapper button:last-child{margin-right: 0;}
@media(max-width:575px){
    .Community-questions h3{font-size: 22px; line-height: 25px; margin-bottom: 10px;}
}