#notfound {height:calc(100vh - 80px);padding: 0 12px;}
#notfound .notfound {max-width: 520px;width: 100%;line-height: 1.4;text-align: center;}
#notfound .notfound .notfound-404 strong.sub-heading {font-family: sans-serif;font-size: 16px;line-height: 20px; font-weight: 700;text-transform: uppercase;color: #505050;letter-spacing: 3px;}
#notfound .notfound .notfound-404 h1 {font-family: sans-serif;font-size: 252px;line-height: 200px;font-weight: 900;margin: 0;color: #FD6F21;text-transform: uppercase;}
#notfound .notfound .notfound-404 h1 {text-shadow: -8px 0 0 #FD6F21;}
#notfound  .notfound h2 {font-family: sans-serif;font-size: 20px;line-height: 24px;font-weight: 700;text-transform: uppercase;color: #505050;margin-top: 0;margin-bottom: 25px;}

@media (max-width: 575px) {
    #notfound .notfound .notfound-404 h1 {
        font-size: 140px;
        line-height: 145px;
        letter-spacing: 0;
    }
    #notfound .notfound h2 {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 319px) {
    #notfound .notfound .notfound-404 h1 {
        font-size: 120px;
        line-height: 125px;
    }
}
