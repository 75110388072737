.informative-links{padding-top: 50px;}
.informative-links h1 .theme{margin-bottom: 30px; display: inline-block;}
.informative-links .informative-ul{list-style: none; display: flex; align-items: center; flex-wrap: wrap; padding: 0; margin: 0;}
.informative-links .informative-ul li{padding: 0px 15px; position: relative; width: 50%; margin-bottom: 20px;}
.informative-links .informative-ul li a{ display: inline-block;  font-size: 20px; line-height: 24px; font-weight: 400; text-decoration: none; transition: all 0.3s ease-in-out; position: relative;}
.informative-links .informative-ul li a:hover{transform: scale(1.07);}
.informative-links .informative-ul li::before{content: ""; position: absolute; top: 50%; transform: translateY(-50%); left: 0; width: 8px; height: 8px; border-radius: 50%;  background-color: #505050;}
/* .informative-links .informative-ul li:last-child::before{display: none;} */
.informative-sec .works-custom-heading h3::after {
  position: absolute;
  content: "";
  width: 90px;
  height: 5px;
  background-image: linear-gradient(to right, #fd6f21, #dd6a2c);
  bottom: -8px;
  left: 0%;
}
/* .informative-sec ul{display: flex;flex-wrap: wrap;} */
/* .informative-sec ul li a {
  color: #fd6f21;
  position: relative;
  text-decoration: none;
  padding-left: 10px;
} */
/* .informative-links ul li a:before{
  content: '';
  display: block;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  background: #fd6f21;
  transform: scaleX(0);
  transition: all ease-in-out .4s;
}
.informative-linksul li a:hover:before{
  transform: scaleX(1);
} */
/* .informative-sec ul li::marker{
  content: "→";
}
.informative-sec ul li a:hover {
  color: #fd6f21;
  opacity: 1;
  text-decoration: none;
}

.informative-sec .informative-col {
  margin-bottom: 30px;
}

.informative-info-content figure.image{
  width: 200px;
  height: 200px;
}

.informative-info-content figure.image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
} */


/* @media (max-width: 1699px) {
    .informative-sec {
        margin-top: 90px;
    }
} */

@media (max-width: 575px) {
  .informative-links .informative-ul li{width: 100%;}
  .informative-links .informative-ul li a{font-size: 16px; line-height: 20px;}
}