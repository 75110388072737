.mainSidebar {position: fixed; top: 95px;  height:calc(100vh - 95px); z-index: 999;}
.sidebarFlex{display: flex; flex-direction: column; justify-content: space-between; min-height: 100%; height: 100%; overflow-y: auto; background: #fff; border-radius: 7px; min-width: 294px;}
.sidebarFlex > .sidebarLink {height:calc(100% - 57px); overflow-y: auto;}
.sidebarStyle.w2{min-width: 90px;}
.sidebarStyle.w2 ul{border-bottom: 0px; padding-top: 50px;}
.sidebarStyle ul li{margin-bottom: 10px;}
.sidebarStyle.w2 ul li a{ padding: 0px 0px 0px 13px; margin-right: 10px;}
.sidebarStyle.w2 ul li a span{padding-left: 5px; margin-right: 10px;}
.sidebarStyle.w2 ul li:not(:last-child) a span { border-bottom: 0px solid #303341; }
.sidebarStyle h3{ font-family: 'outfit';  font-size: 20px;  line-height: 24px;  font-weight: 700; padding: 30px 0px 30px 30px; color: #000; text-transform: capitalize;border-bottom:1px solid #D5D5D5}
.sidebarStyle ul{padding-left: 0px; margin-bottom: 0px; list-style: none;}
.sidebarStyle ul li a{display: block; font-family: 'outfit';  font-size: 16px;  line-height: 15px;  font-weight: 500; padding: 0px 0px 0px 25px; color: #000; text-transform: capitalize; text-decoration: none; margin-right: 29px; border-radius: 0px 25px 25px 0px; transition: all ease-in-out .4s;}
.sidebarStyle ul li a span { display: inline-block; padding: 10px 0px 10px 0px; width: 100%; padding-left: 15px;}
/* .sidebarStyle ul li:not(:last-child) a span { border-bottom: 1px solid #303341; } */
.sidebarStyle ul li a:hover, .sidebarStyle ul li .active{background: transparent linear-gradient(98deg, #505050 0%, #E37229 100%) 0% 0% no-repeat padding-box;color:#fff;}
.sidebarStyle ul li a:hover span, .sidebarStyle ul li a.active span{border-color: transparent;}
.sidebarStyle ul li a:hover img, .sidebarStyle ul li a.active img{filter:invert(1)}
/* .sidebarLogo{float: right; font-size: 22px; color: #FD6F21; cursor: pointer; margin: -20px; width: 65px; height: 65px; border-radius: 50%; background: #FD6F2180; display: flex; align-items: center; justify-content: center; transition: all ease-in-out .4s; transition-delay: .2s;} */
.sidebarLogo{float: right; font-size: 22px; color: #fff; cursor: pointer; margin: -20px; width: 65px; height: 65px; border-radius: 50%; background: #FD6F21; display: flex; align-items: center; justify-content: center; transition: all ease-in-out .4s; transition-delay: .2s;display:none;}
.rotate{transform: rotate(-135deg);}
.logo-change{transform: rotate(90deg);}
/* .mainSidebar .orange-btn {transition: all ease-in-out .2s;padding: 0;margin: 0 auto;font-size: 14px;line-height: 18px;} */
.mainSidebar .orange-btn{width:100%;max-width:189px;height:49px;border-radius:25px;border:none;color:#fff;font-size:18px;line-height:18px;font-weight:400;background: transparent linear-gradient(270deg, #505050 0%, #E37229 100%) 0% 0% no-repeat;cursor:pointer;}
.mainSidebar .orange-btn:hover{background: transparent linear-gradient(0deg, #505050 0%, #E37229 100%) 0% 0% no-repeat;}

.logout{background-color: transparent; border:0px; transition: all ease-in-out 0s;}
.logout svg{ font-size: 35px; color: #fff;}
.sitePages{float: right;padding: 30px 30px 59px 30px;}
.sitePages-main-width{width: calc(100% - 295px);overflow-y: auto;}
/* .sitePages-main-width, .sitePages-width{margin-top: 90px;} */
/* .sitePages.sitePages-main-width .dashboardHeader, */
.sitePages.sitePages-main-width .wrapVideos,
.sitePages.sitePages-width .dashboardHeader,
.sitePages.sitePages-width .wrapVideos{padding: 0;}
/* .right.dashboard-sitePages.sitePages-width .v-player-container{margin-top: 20px;} */
.sitePages-width{width: calc(100% - 130px);height: calc(100vh - 90px);}
.logout svg:hover{color: #FD6F21;}
.mainSidebar .sidebar-img{width: 30px;height: 30px;}
.mainSidebar .mini-sidebar .sidebar-img{width: 50px;height: 50px;}
.mainSidebar .sidebar-img img{width: 100%;height:100%;object-fit:contain;transition: all ease-in-out .4s;}
/**/
.mainSidebar .sidebar-img-first{width: 30px;height: 30px;padding: 4px;}
.mainSidebar .mini-sidebar .sidebar-img-first{width: 50px;height: 50px;padding: 8px;}
.mainSidebar .sidebar-img-first img{width: 100%;height: 100%;object-fit: cover;}
/**/
.mainSidebar .mini-sidebar .menu-name{display: none;}
.mainSidebar .mini-sidebar .sidebar-img {margin-right: 0 !important;}
.right.dashboard-sitePages.sitePages-width{margin-top: 0;}
.sitePages .dashboardHeader{margin-bottom: 12px;height: 100%;}
/**/
.profile-sec h4 {font-family: 'outfit';font-size: 24px;line-height: 30px;font-weight: 500;color: #505050;}
 /**/
 .mainSidebar .mobile-button {font-size: 16px;color: #fff;cursor: pointer;align-items: center;justify-content: center;transition: all ease-in-out .4s;transition-delay: .2s;width: 30px;height: 30px;border-radius: 0px 8px 8px 0px;background: #FD6F21;box-shadow: 0px 0px 5px 3px #0d0f17;z-index: 0;position: absolute;right: -30px;top: 15px;display: none;}
/**/
.memberImg img{max-width: 180px;}

/*RESPONSIVENESS*/
@media (max-width:1699px) {
.sitePages-main-width { width: calc(100% - 250px);height: calc(100vh - 92px);}
.mainSidebar{top: 91px;}
.explore{padding-top: 105px;}
.sidebarStyle h3{ font-size: 18px; line-height: 24px; padding: 20px 0px 20px 20px;}
.sidebarStyle ul li a { font-size: 14px; line-height: 20px; padding: 0px 0px 0px 15px;}
.sidebarStyle ul li a span { padding: 8px 0px 8px 0px; padding-left: 13px;}
.sidebarStyle ul li a img{width: 15px;}
.sidebarLogo{width: 45px; height:45px;}
.sidebarLogo svg{width: 15px;}
.sidebarFlex {min-width: 247px;}
.memberImg img{max-width: 120px;}
}
@media (max-width:1399px) {
.sidebarStyle h3{ font-size: 16px; line-height: 20px; padding: 15px 0px 15px 15px;}
.sidebarLogo{width: 35px; height:35px;}
.sidebarLogo svg{width: 10px;}
.sitePages { padding: 20px 20px 35px 20px;}
.sidebarStyle.w2{min-width: 50px;}
.logout svg { font-size: 20px;}
.sidebarStyle .orange-btn{margin-top: 20px;}
.sitePages-width{width: calc(100% - 100px);}
/**/
.explore {padding-top: 60px;}
/**/
.sitePages-main-width {height: calc(100vh - 82px);}
.sitePages-width{height: calc(100vh - 82px);}
.v-player-container .v-custom-sitecards h2 {font-size: 26px;line-height: 32px;}
.v-player-container .exploreCard .exploreImg img{height: 130px;}
.v-custom-sitecards.recomemded-videos .exploreImg img{height:100%}
}
@media (max-width:1199px) {
   .v-player-container .v-custom-sitecards h2 {font-size: 22px;line-height: 28px;}
}
@media (max-width:991px) {
.sitePages-main-width { width: 100%; padding-left: 30px;}
/* .sidebarFlex{width: 0px;} */
.sidebarStyle.w2 ul li a { padding: 0px 0px 0px 7px;}
/**/
.mainSidebar {top: 85px;margin: 0px;position: absolute;height: calc(100vh - 85px);left: -250px;transition: all ease-in-out 0.3s;}
.mainSidebar.show {left: 0px;transition: all ease-in-out 0.3s;}
.sidebarLogo {display: none;}
.mainSidebar .mobile-button {display: flex;}
}
 @media (max-width:575px){
    .sitePages-width {width: calc(100% - 85px);}
 }
 @media (max-width:467px) {
.mainSidebar {top: 80px; height: calc(100vh - 80px);}
.sitePages-width{width: calc(100% - 90px);}
.sidebarStyle.w2 ul li a{margin-right: 0;}
 }
 @media (max-width:379px) {
    .sitePages {
        padding: 20px 10px 35px 10px;
    }
 }
 @media (max-width: 319px) {
.mainSidebar {height: calc(100vh - 122px);}
 }