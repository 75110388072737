/**/ 
.user .userLink::-webkit-scrollbar {background-color: #151721 ; width: 4px;display: none;}
.user .userLink:hover::-webkit-scrollbar {display: block;}
.user .userLink::-webkit-scrollbar-button { background-color: #151721 ; color: #151721 ; }
.user .userLink::-webkit-scrollbar-track  { background-color:#151721 ; }
.user .userLink::-webkit-scrollbar-track-piece  { background-color: #151721 }
.user .userLink::-webkit-scrollbar-thumb  {  background:  #FD6F21; }
.user .userLink::-webkit-scrollbar-corner { background-color:#151721 ;  }
.user .userLink::-webkit-resizer {background-color:#151721 ; }
.user .userLink::-webkit-scrollbar-button:single-button {display: none;}
/* .user .userLink::-webkit-scrollbar-button:single-button {background-color: #151721 ;display: block;border-style: solid;height: 15px;width: 10px;}
.user .userLink::-webkit-scrollbar-button:single-button:vertical:decrement {border-width: 0 5px 5px 5px;border-color: transparent transparent #FD6F21 transparent;}
.user .userLink::-webkit-scrollbar-button:single-button:vertical:increment {border-width: 5px 5px 0 5px;border-color: #FD6F21 transparent transparent transparent;} */
.user .userLink{scrollbar-color: #FD6F21 #151721  !important; scrollbar-width: thin;}
/**/
.dashboard-Sidebar {min-width:70px;position: fixed; top: 90px; height:calc(100vh - 90px); z-index: 999;}
.MobileSidebar { position: fixed; top: 90px; z-index: 999; width: 100px;height:calc(100vh - 90px); background: #fff; border-radius: 7px; border-bottom-right-radius: 50px; margin-top: 23px; left: 10px;;}
.dashboardFlex{display: flex; flex-direction: column; justify-content: space-between; background: #fff; width: 328px;}
.dashboard-sitePages{ float: right; height: 100%;padding-top:30px;padding-bottom:50px; position: relative;}
.dashboardBody{background-color: #F2F3F8; height: 100%;overflow: hidden;}
.dashboard-main-width{width: calc(100% - 328px);}
.dashboard-width{width: calc(100% - 0px);}
.dashboardLogo{padding: 26px 0px 26px 40px; border-bottom: 1px solid #303341;}
.dashboardLogo img{width: 120px;}
.autoBar{height: calc(100vh - 92px);} 
.user{ z-index: 999; height: 100%; overflow-x: hidden; display: flex; flex-direction: column; justify-content: space-between;}
.user .userLink{ height: calc(100% - 90px);overflow-y: auto;padding-top: 40px;}
.user .logout-button-div {padding: 14px 20px 20px;height: 84px;}
.dashboard-Sidebar .user .dbLogoutBtn{width:100%;max-width:189px;height:49px;border-radius:25px;border:none;color:#fff;font-size:18px;line-height:18px;font-weight:400;background: transparent linear-gradient(270deg, #505050 0%, #E37229 100%) 0% 0% no-repeat;cursor:pointer;}
.dashboard-Sidebar .user .dbLogoutBtn:hover{background: transparent linear-gradient(0deg, #505050 0%, #E37229 100%) 0% 0% no-repeat;}
.MobileSidebar .userLink{overflow-y: auto; margin-bottom: 20px;}
.MobileSidebar .user{height: 80vh;}
.user .orange-btn {padding: 13px 23px;font-size: 14px;line-height: 18px;margin: 0 auto;}
.userBlock{margin-bottom: 30px; text-align: center; display: inline-block; width: 100%;}
.userBlock .userImg{width: 150px; height: 150px; border-radius: 50%; margin-bottom: 20px;}
.userBlock .userImg img{object-fit: cover;border-radius: 50%; /*  box-shadow: #fc491ed4 0px 7px 29px 0px; */}
.userBlock h3{font-family: 'outfit';  font-size: 24px;  line-height: 30px;  font-weight: 500; color: #505050; margin-bottom: 0px; padding:0px;}
.userBlock p{font-family: 'outfit';  font-size: 16px;  line-height: 20px;  font-weight: 500;opacity: 0.4;}
.dashboard-ul{padding-left: 0px; list-style: none;}
.dashboard-ul li{margin-bottom: 0;}
.dashboard-ul li a{ display: block; font-family: 'outfit';  font-size: 16px;  line-height: 20px;  font-weight: 500; color: #fff; text-decoration: none; transition: all ease-in-out .4s;padding: 25px 0px 25px 40px;color:#505050}
.dashboard-ul li a:hover, .dashboard-ul li a.active{background-color: #F2F3F8;}
.sitePages h2, .dashboard-sitePages h2{font-family: 'outfit';  font-size: 30px;  line-height: 38px;  font-weight: 500; color: #505050; margin-bottom:40px;}
.dashboard-sitePages h4{font-family: 'outfit';  font-size: 24px;  line-height: 30px;  font-weight: 500; color: #505050; margin-bottom: 20px;}
.sitePages p, .dashboard-sitePages p{font-family: 'outfit';  font-size: 16px;  line-height: 22px;  font-weight: 300; color: #212529;}
.dashboard-sitePages .dashboard-p{font-family: 'outfit';  font-size: 16px;  line-height: 22px;  font-weight: 500; color: #fff;opacity: 0.4;} 
.logoTrue{font-size: 22px; color: #fff; cursor: pointer;  display: flex; align-items: center; justify-content: center; transition: all ease-in-out .4s; transition-delay: .2s; width: 55px; height: 55px;background: #FD6F21; box-shadow: 0px 0px 5px 3px #0d0f17; 
position: absolute;top:0;right:-50px; z-index: -1;border-radius:4px;box-shadow:none;display: none;}
.dsahboardLogo{ transform: rotate(0deg);}
.MobileSidebar .dashboard-ul li a{padding: 25px;text-align: center;}
.MobileSidebar .user{overflow: hidden;}
.MobileSidebar .dashboardLogo > div{width: 55px; margin: auto;}
.MobileSidebar .dashboardLogo img{width: 100%; height: 100%; object-fit: cover;}
.sitePages .analytic-box .analytic-block, .dashboard-sitePages .analytic-box .analytic-block{background:#fff;padding:20px;border-radius:10px;height:100%;}
.dashboard-sitePages .analytic-box label{font-size:16px;line-height:22px;font-family: 'outfit';font-weight: 300;color: #505050;margin:0 0 8px;display:block;}
.sitePages .react-time-picker, .dashboard-sitePages .react-time-picker{width:100%;display: block;}
.sitePages .react-time-picker__wrapper, .dashboard-sitePages .react-time-picker__wrapper{border: 1px solid #E1E1E1;border-radius:10px;width:100%;height:40px;font-size:16px;line-height:20px;}
.sitePages .custom-react-select .triage-select__control, .dashboard-sitePages .custom-react-select .triage-select__control{border-color:#e1e1e1 !important;border-radius:10px !important;}
.sitePages .btns-holder button,  .dashboard-sitePages .btns-holder button{width:49%;}
.dashboard-sitePages .btns-holder button.orange-main-button:hover{color:#FD6F21}
.dashboard-sitePages .btns-holder button.black-btn:hover{color:#fff;background:#FD6F21;border-color:#FD6F21;}
/**/ 
.dashboard-Sidebar .mobile-button {font-size: 16px;color: #fff;cursor: pointer;align-items: center;justify-content: center;transition: all ease-in-out .4s;transition-delay: .2s;width: 30px;height: 30px;border-radius: 0px 4px 4px 0px;background: #FD6F21;z-index: 0;position: absolute;right: -30px;top:0;display: none;}
/*  */
.contact-main{height: calc(100vh - 155px); width: 100%; padding: 0 70px;}
.contact-main .contact-wrapper{height: calc(100% - 180px); overflow-y: auto;  width: 100%;  padding-right: 15px;}
.send-message-from textarea{resize: none; min-height: 50px;}
.send-message-from .form-control{height: auto;}
.contact-main .send-message-from { position: absolute; bottom: 30px; width: 100%; left: 50%; transform: translateX(-50%); background-color: #F2F3F8; padding: 0 70px;} 
.contact-main .submit-btn{display: flex; justify-content: flex-end;} 
/*rersponsiveness*/
@media (max-width:1699px) {
	.dashboard-sitePages h2 {font-size: 26px;line-height: 32px;margin-bottom: 30px;}
	.dashboard-sitePages h4{font-size: 18px;  line-height: 22px;  margin-bottom: 10px;}
	/* .dashboard-sitePages p, .dashboard-sitePages .dashboard-p{font-size: 12px;  line-height: 20px;}  */
	.dashboardFlex{width: 300px;}
	.dashboard-main-width { width: calc(100% - 300px);}
	.logoTrue{font-size: 16px; width: 40px; height: 40px;}
	.MobileSidebar{width: 65px;}
	.MobileSidebar .dashboardLogo > div{width: 35px; }
	.MobileSidebar .dashboard-ul li a{padding: 20px;}
	/**/
	.modal .orange-btn, .video-modal .modal-footer .orange-btn {font-size: 14px;line-height: 20px;}
	/**/
	.userBlock .userImg {width: 120px;height: 120px;}
	.user .userLink {padding-top: 25px;}
	.userBlock {margin-bottom: 15px;}
	.dashboard-ul li a {padding: 20px 0px 20px 45px;font-size: 14px;line-height: 18px;}
}
@media (max-width:1399px) {
	.dashboardLogo{padding: 18px 0px 18px 23px;}
	.dashboardLogo img{width: 100px;}
	.dashboard-ul li a{font-size: 14px;  line-height: 18px; padding: 18px 0px 18px 23px;}
	.dashboard-ul li a img{width: 17px;}
	.userLink{padding-top: 25px;}
	.userBlock .userImg{width: 100px; height: 100px; margin-bottom: 10px;}
	/* .dashboard-sitePages h2{ font-size: 20px;  line-height: 24px; margin-bottom: 15px;} */
	.dashboard-sitePages h4{font-size: 16px;  line-height: 20px;  margin-bottom: 8px;}
	.userBlock h3{font-size: 20px;  line-height: 26px; }
	.userBlock p{font-size: 11px;  line-height: 14px; }
	/**/
	.autoBar {height: calc(100vh - 70px);}
	.user .logout-button-div {height: 78px;padding:6px 20px 16px;}
	.user .userLink {height: calc(100% - 78px);}
}
@media (max-width:1199px){
	.autoBar{height: calc(100vh - 70px); }
	.user .logout-button-div {height: 80px;position:absolute;left:0;right:0;bottom:10px;}
	.user .userLink {height: calc(100% - 90px);}
	.dashboardFlex {width: 250px;}
	.dashboard-main-width {width: calc(100% - 250px);}
	.dashboard-sitePages h2 {font-size: 20px;line-height: 26px;margin-bottom: 24px;}
}
@media (max-width: 991px) {
/* .dashboard-main-width { width: calc(100% - 50px);} */
.MobileSidebar .dashboard-ul li a{padding: 18px;}
.MobileSidebar .user{padding: 0px 0px 70px 0px; }
	.dashboardFlex {width: 220px;}
	.dashboard-main-width {width: calc(100% - 220px);}
	.contact-main{padding: 0 30px;}
	.contact-main .send-message-from{padding: 0 30px;}
}
@media (max-width: 575px)  {
	.dashboard-main-width {width: calc(100% - 85px);}
	.userBlock .userImg {width: 80px;height: 80px;}
	.dsahboardLogo-change, .logoTrue {display: none;}
	.MobileSidebar {height: 96vh;margin: 2vh 0;}
	/**/
	.dashboardLogo {padding: 18px 0px 18px 0px;text-align: center;}
	.dashboard-Sidebar .mobile-button{display: flex;}
	.dashboardFlex {width: 220px;position: absolute;left: -220px;transition: all ease-in-out 0.3s;}
	.dashboardFlex.show {left: 0px;transition: all ease-in-out 0.3s;}
	.dashboard-main-width {width: 100%;}
	.dashboard-sitePages{ padding-top:70px;}
	.contact-main .submit-btn{justify-content: flex-start;}
}
@media (max-width: 479px) {
	.dashboardFlex {width: 200px;left: -200px;}
	.contact-main{padding: 0 20px;}
	.contact-main .send-message-from{padding: 0 20px;}
}
@media(max-width:320px){
	.sitePages h2, .dashboard-sitePages h2{font-size: 22px; line-height: 26px;}
}