.view-invoce-sec {
  background-color: #F2F3F8;
}
.invoice-header {
  background:#fff;
  padding: 20px 12px;
}
.view-invoice-p {
  max-width: 1620px;
  padding: 0 12px;
  margin: 0 auto;
}
/**/
/* .view-invoice-table .table {--bs-table-bg: #151721;border-radius: 20px;overflow: hidden;} */
.view-invoice-table .table th {padding: 33px 30px;}
.view-invoice-table .table td {padding: 30px 30px;}
.view-invoice-table .table tbody tr:last-of-type td {border: none;}
.view-invoice-table .table tbody tr td {border-bottom: 1px solid #282C3C;}
.view-invoice-table .table thead tr th {border-bottom: 1px solid #282C3C;}
.view-invoice-table .table tbody tr td button{transition: all ease-in-out 0.3s;}
.view-invoice-table .table tbody tr td button,.view-invoice-table .table tbody tr td button:focus{background: transparent;border:1px solid #FD6F21;color:#FD6F21;border-radius: 8px;height: 48px;width: 48px;min-height: auto;}
.view-invoice-table .table tbody tr td button:hover{background:#FD6F21;color:#fff;}
.view-invoce-sec .wrapper-content{padding:20px;border-radius:5px;background: #fff;height:100%;}
.view-invoce-sec .wrapper-content p {font-size: 16px;}
.view-invoce-sec .wrapper-content .border-b {border:solid #E1E1E1;border-width:1px 1px 0;padding: 0 12px;}
.view-invoce-sec .wrapper-content .border-b:last-child,
.bill-container .bill-wrapper .border-b:last-child{border-bottom-width: 1px;}
/*print invoice section*/
.view-invoce-sec .table {background: #fff;border-radius: 5px;overflow: hidden;}
.view-invoce-sec .table tbody tr:last-of-type td {border: none;}
.view-invoce-sec .table tbody tr td {border-bottom: 1px solid #282C3C;}
.view-invoce-sec .table thead tr th {border-bottom: 1px solid #282C3C;}
.bill-container .bill-wrapper{max-width: 500px;margin-left: auto;padding:20px;border-radius: 5px;background:#fff;}
.bill-container .bill-wrapper .border-b{border:solid #E1E1E1;border-width:1px 1px 0;padding:2px 7px;}
.bill-container .bill-wrapper p{font-size: 16px;}
/*media quries*/
@media (max-width:1599px) {
  .view-invoice-table .table {min-width: 1260px;}
}
@media (max-width:767px){
  .view-invoce-sec .wrapper-content{margin:0 0 15px;height:auto;}
}