.header{background:#fff; position: relative;z-index: 9999;}
.scrolled .header,
.header.loggedin{position: fixed;left:0;top:0;right:0;z-index:9999; transition: all ease-in-out 0.8s;}
.navbar{padding: 13px 0px 12px;}
.wrapHeaderBar{position: relative;width: 100%;}
.navbar-brand{padding: 0px;width: 140px;margin:0 150px 0 0;}
.navbar-expand-lg .navbar-toggler{display: block;min-height: auto;}
.navbar-nav { flex-direction: row; justify-content: end; align-items: center; height: 100%; }
.navbar-collapse , .navbar-expand-lg .navbar-collapse{position: absolute; top:50%;right: 95px;-o-transform:scale(1) translateY(-50%);-ms-transform:scale(1) translateY(-50%);-moz-transform:scale(1) translateY(-50%);-webkit-transform:scale(1) translateY(-50%);transform:scale(1) translateY(-50%);transform-origin: 100% 50%;transition: all ease-in-out 0.3s;}
.collapse:not(.show){position: absolute;-o-transform:scale(0);-ms-transform:scale(0);-moz-transform:scale(0);-webkit-transform:scale(0);transform:scale(0);}
.header .navbar-expand-lg .navbar-nav .nav-link {color:#E37229; border-radius: 10px; font-size: 16px; line-height:20px; font-weight: 500; text-transform: capitalize; transition: all ease-in-out .4s; text-transform: uppercase;margin:0 0 0 40px;padding:0;}
.header .navbar-expand-lg .navbar-nav .nav-link:hover{ color: #FD6F21;}
.header .dropdown-item.active, .header .dropdown-item:active { background-color: #FD6F21;color:#fff;}
.header .dropdown-item.active p, .header .dropdown-item:active p{color:#fff;}
.header .navbar-nav .dropdown-menu{width: 300px;}
.navbar>.container-fluid{flex-direction: column;}
.nav-flex{display: flex; justify-content: space-between; align-items: center; width: 100%;}
.navbar-toggler { padding: 0;font-size: 0; line-height: 0;border: 0px solid transparent; border-radius: 0;}
.navbar-toggler:focus{box-shadow: none;}
.nav-right-bar{display: flex;}
.nav-right-bar div:not(:last-child){margin-right:8px;}
.navBtns{display: flex; align-items: center; justify-content: center; border-radius: 10px; cursor: pointer;}
.navBtns:hover svg{color: #fff;}
.header .user-links-block{-o-transform:scale(1);-ms-transform:scale(1);-moz-transform:scale(1);-webkit-transform:scale(1);transform:scale(1);transform-origin: 100% 50%;transition: all ease-in-out 0.3s;}
.header .user-links-block.hide{-o-transform: scale(0);-ms-transform: scale(0);-moz-transform: scale(0);-webkit-transform: scale(0);transform: scale(0);}
.header .search-form{width:816px;height:46px;padding:4px 13px;border:1px solid #DBDBDB;border-radius:7px;background:#F2F3F8;display:none; position: relative;}
.header .search-form{display: flex;}
.header .search-form .search-icon{font-size:16px;line-height:16px;margin:0 15px 0 0;color:#E37229;}
.header .search-form input{border:none;background:none;font-size:16px;line-height:20px;color:#000;font-weight:700;opacity:.62;}
.header .search-form input:focus{border:none;box-shadow: none;outline:none;}
.header .header-btn{width:177px;height:55px;font-size:18px;line-height: 23px;font-weight:600;color:#000;position:relative;background: linear-gradient(180deg, rgba(227,114,41,1) 0%, rgba(80,80,80,1) 100%);border-radius:10px;}
.header .header-btn span{position: relative;z-index:1;}
.header .header-btn:after{content:"";width:calc(100% - 4px);height:calc(100% - 4px);background:#fff;border-radius:10px;position: absolute;left:2px;top:2px;}
.header .header-user-btn{width: 36px; height: 36px;color:#fff;border-radius:50%;background: rgb(227,114,41);background: linear-gradient(180deg, rgba(227,114,41,1) 0%, rgba(80,80,80,1) 100%);transition:all ease-in-out 0.3s;}
.header .header-btn:hover,
.header .header-user-btn:hover{background: rgb(227,114,41);background: linear-gradient(0deg, rgba(227,114,41,1) 0%, rgba(80,80,80,1) 100%);}
.header .user-login{border-radius:50%;}
.navBtns:hover .faBar span{background-color: #fff;}
.navBtns  svg{color: #6e718b; font-size: 20px;}
.wrapSearch{display: flex; position: relative;}
.openSearch{position: absolute; right: 130px; top:0; bottom:0; width: 450px; height: 52px; transition: 0.5s linear;display: flex !important;align-items: center;}
.openSearch input{margin-bottom: 0px;height: 52px;}
.openSearch input::placeholder {color: rgba(255, 255, 255, 0.5); }
.searchIcon svg{position: absolute; bottom: 0; top: 0; right: 10px; margin: auto; font-size: 20px; filter: brightness(0) invert(1); transition: all ease-in-out .4s; }
.searchIcon{cursor: pointer;}
.faBar {position: relative; height: 100%; width: 100%;     display: flex; flex-direction: column; justify-content: center; align-items: center;}
.faBar span{ display: block; width: 13px; height: 2px; background-color: #fff; margin: 2px 0px;transition: all .4s ease-in-out;}
.faCross span{position: absolute; right: 0; left: 0; top: 0; bottom: 0;margin: auto;transition: all .4s ease-in-out;}
.faCross span:nth-child(1){transform: rotate(-45deg);}
.faCross span:nth-child(2){visibility: hidden; display: none;}
.faCross span:nth-child(3){transform: rotate(45deg);}
.openSearch .navBtns {min-height: unset;border: 0;padding: 0 18px;}
.sidebar-video-wrapper {padding: 20px 40px;}
.sidebar-video-wrapper p {font-size: 16px;line-height: 20px;}
.sidebar-video-wrapper .opacity-p {font-size: 14px;line-height: 18px;opacity: 0.4;text-transform: uppercase;}
.nav-right-bar .dropdown-menu.show{right: 0;left: auto;}
.nav-right-bar .go-tp-profile{margin-top: 0;margin-right: 0;margin-left: 0;}
.dropdown-menu.show .dropdown-item a{color: #fff;}
.dropdown-item.active, .dropdown-item:active {background-color: #fd6f21;}
.dropdown-item.active p, .dropdown-item:active p {opacity: 1;}
.go-tp-profile .dropdown-item{padding: 10px 16px;}
.go-tp-profile .dropdown-item p{font-family: 'outfit';font-size: 16px;line-height: 22px;font-weight: 300;}
.go-tp-profile .dropdown-item.active p, .go-tp-profile .dropdown-item:active p{opacity: 1;}
.header.active-user .navbar-collapse {right: 150px;}
.header.active-user .openSearch {right: 237px;}
.search-results {width: 100%;position: absolute;top: calc(100% + 12px);left: 0; z-index: 1000;}
.search-results .search-img {width: 100%;height: 100px;}
.search-results .search-img .videoImg {width: 100%;height: 100%;object-fit: cover;}
.search-results .search-popup-close-btn{padding: 0; position: absolute; z-index: 1000; top: -10px; right: -10px; border-radius: 50%; background: transparent; border: none; transition: all 0.3s ease-in-out; transform: rotate(360deg);}
.search-results .search-popup-close-btn:hover{ transform: rotate(-360deg);}
.header .upload-btn{background: linear-gradient(180deg, rgba(227,114,41,1) 0%, rgba(80,80,80,1) 100%); border: none; box-shadow: none; outline: none;}
.header .upload-btn:hover{background: linear-gradient(0deg, rgba(227,114,41,1) 0%, rgba(80,80,80,1) 100%);}
.Community-btn{padding: 6px 12px; color: #fff; border-radius: 6px; font-size: 16px; line-height: 24px; font-weight: 400; margin-left: 10px;}
/* MEDIA QURIES */
@media (max-width:1860px){
	.header .search-form{width:700px;}
}
@media(max-width:1722px){
	.header .navbar-expand-lg .navbar-nav .nav-link{margin: 0 0 0 15px;}
}
@media (max-width:1699px) {
	.navbar-brand{width: 180px;margin:0 70px 0 0;}
	.navBtns{ width: 40px; height: 40px; border-radius: 8px;}
	.navbar-collapse , .navbar-expand-lg .navbar-collapse{ right: 115px; }
	.nav-right-bar div:not(:last-child){margin-right:8px;}
	.navBtns  svg{font-size: 14px;}
	.header .navbar-expand-lg .navbar-nav .nav-link {padding: 15px 0px;}
	.openSearch input {height: 40px;}
	.openSearch {right: 105px;}
	.sidebar-video-wrapper {padding: 20px 30px;}
	/* .header.active-user .navbar-collapse {right: 195px;} */
	.header .navbar-expand-lg .navbar-nav .nav-link { padding: 8px 0px; font-size: 14px; line-height: 20px;}
	.header.active-user .openSearch{right: 200px;}
	.header .search-form{width:630px;}
}
@media (max-width:1439px){
	.header .search-form{width:550px;}
	.navbar-brand{width: 150px;margin:0 50px 0 0;}
}
@media (max-width:1399px) {
	
	.navBtns{ width: 35px; height: 35px; border-radius: 5px;}
	.nav-right-bar div:not(:last-child){margin-right:7px;}
	.searchStyle, .openSearch{height: 45px;}
	.sidebar-video-wrapper {padding: 20px;}
	.openSearch {right: 93px;}
	.openSearch input {height: 35px;}
	.navbar-collapse, .navbar-expand-lg .navbar-collapse {right: 97px;}
	/* .header.active-user .navbar-collapse {right: 165px;} */
	/* .header.active-user .openSearch, .search-results {right: 170px;} */
	.header .search-form .input-holder input::placeholder{ font-size: 13px; line-height: 16px;}
}
@media(max-width:1300px){
	.header .search-form { width: 430px;}
}
@media (max-width:1200px){
	.navbar-brand{width:120px;}
	.header .search-form{width:370px;}
}
@media(max-width:1199px){
	.header .search-form{position: absolute;width:100%;left:0;top:calc(100% + 15px);background: #fff;z-index:2;-o-transform: scale(0);-ms-transform: scale(0);-moz-transform: scale(0);-webkit-transform: scale(0);transform: scale(0);transform-origin:50% 50%;transition: all ease-in-out 0.3s;}
	.header .search-form.show{-o-transform: scale(1);-ms-transform: scale(1);-moz-transform: scale(1);-webkit-transform: scale(1);transform: scale(1);}
}
@media(max-width:1100px){
	/* .header.active-user .navbar-collapse { right: 90px;} */
	.header .navbar-expand-lg .navbar-nav .nav-link { margin: 0 0 0 8px; font-size: 12px;line-height: 19px;}
	
}
@media(max-width:1000px){
	.navbar-brand { width: 120px; margin: 0 25px 0 0;}
	.header .search-form { width: 354px;}
}
@media (max-width:991px) {
	.openSearch{width: 400px;}
	.navbar-nav{flex-direction: column; justify-content: flex-start; align-items: center; padding-left: 0px;margin-top: 12px;}
	.navbar-nav{display:block;}
	.navbar-collapse, .navbar-expand-lg .navbar-collapse{width: 100%;top:100%;-o-transform: translateY(0);-ms-transform: translateY(0);-moz-transform: translateY(0);-webkit-transform: translateY(0);transform: translateY(0);left:0;right:0;z-index:3;}
	.header .navbar-expand-lg .navbar-nav .nav-link:first-child{border-top:1px solid #E37229;}
	.header .navbar-expand-lg .navbar-nav .nav-link{padding:10px 10px;background:#E37229;margin:0;display:block;border-radius: 0;color:#fff;border-bottom:1px solid #fff;transition: all ease-in-out 0.3s;}
	.header .navbar-expand-lg .navbar-nav .nav-link:hover{background:#fff;color:#E37229}
	.openSearch input {height: 100%;}
	/* .header.active-user .openSearch, .header .search-results {right: 0px;width: 100%;z-index: 9999;} */
	.header.active-user .openSearch{top: calc(100% + 20px);}
	/* .header .search-results {top: calc(100% + 65px);} */
	.header .search-result-items {background: #151721;}
	.header .search-form { width: 100%;}

}
@media (max-width:767px) {
	.navbar-collapse, .navbar-expand-lg .navbar-collapse{ padding: 0;}
	.searchStyle, .openSearch{width: 350px;}
	.search-results{top: 125px;}
	.search-results .search-img{height: 80px;}
	.header .header-btn{width:36px;height:36px;}
	.navbar-brand {width: 130px;}
}
@media (max-width: 575px) {
	.searchStyle, .openSearch{width: calc(100% - 100px)}
	.sidebar-video-wrapper {padding: 20px 15px;}
	.search-results.non-active-user{width: calc(100% - 100px);}
	.search-results .search-popup-close-btn{top: -12px; right: 0px;}
} 
@media (max-width: 479px) {	
	.search-results{padding: 0 25px;}
	.openSearch input {padding: 10px 35px 10px 10px;}
	.navbar-brand {width: 100px;}
	.header .header-user-btn{width:30px;height:30px;}
	.nav-right-bar div:not(:last-child){margin-right:5px;}
	.header .upload-btn, .header .Community-btn { padding: 3px 5px; font-size: 14px; line-height: 22px; margin-left: 5px;}
} 

@media (max-width: 319px) {
	.navbar-brand{margin: auto auto 10px auto; text-align: center;}
}
@media(max-width:290px){
	.header .upload-btn, .header .Community-btn{font-size: 13px; line-height: 16px; width: 22px; height: 22px;}
	.header .header-user-btn { width: 22px; height: 22px; font-size: 13px; line-height: 16px;}
	/* .faBar span{width: 9px; margin: 1px 0;}
	.faCross span:nth-child(1){left: 50%; top: 50%; transform: rotate(-45deg) translate(-50%, -50%);}
	.faCross span:nth-child(3){left: 50%; top: 50%; transform: rotate(45deg) translate(-50%, -50%);} */
}