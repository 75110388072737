.dashboardHeader {margin-bottom: 40px;}
.dashboardHeader h2 {margin-bottom: 0;text-transform: capitalize !important;}
.px40{padding-inline: 40px;}
/* .dashboardHeader h2{font-family: 'outfit';  font-size: 30px;  line-height: 38px;  font-weight: 500; color: #fff; margin-bottom: 0px;} */
.box{background: #fff; border-radius: 20px; padding: 40px;height: 100%;}
.boxMb80{margin-bottom: 80px;}
.upload {margin: auto; text-align: center;}
.upload img {margin-bottom:40px;}
.upload .orange-btn{margin-top: 50px;padding: 20px 38px;}
.upload p{margin-bottom: 0px;}
.analytics h4{margin-bottom: 6px;}
/* .analytics p{font-family: 'outfit';  font-size: 16px;  line-height: 22px;  font-weight: 500; color: #fff; margin-bottom: 0px;} */
.analyticBox.box{padding-left:0px; padding-right:0px;}
.analytics .analyticSummary p{font-family: 'outfit';  font-size: 16px;  line-height: 48px;}
.analyticSummary ul{padding: 0px 40px; list-style: none; margin: 30px 0px;}
.analyticSummary ul li{display: flex; justify-content: space-between;}
.analyticBox.box > h2 ,.analyticBox.box .analytics > div, .analyticBox.box > ul { margin-left: 40px; margin-right: 40px;}
.analyticBox.box .analytics .analyticSummary{padding: 0px; border-bottom: 1px solid #585960; margin: 0px 0px 30px 0px;}
.analytics p{font-family: 'outfit';  font-size: 22px;  line-height: 48px;  font-weight: 500; color: #505050; margin-bottom: 0px;}
.count{display: block; font-family: 'outfit';  font-size: 42px;  line-height: 48px;  font-weight: 500; color: #505050; margin-top: 30px; opacity: 0.6;}
.analytics p.md-16{font-size: 16px;  line-height: 20px;}
.wrapVideoInfo ul{padding-left:0px;list-style: none;}
.wrapVideoInfo ul li .videoInfoDiv{display: flex; align-items: center; border-bottom: 1px solid #585960; padding: 20px 0px;}
.videoInfoDiv .videoImg{width: 100px; height: 90px; border-radius: 8px;}
.videoInfoDiv .videoImg img {width: 100%; height: 100%; object-fit: cover; border-radius: 8px;}
.videoInfoDiv h4{font-family: 'outfit';  font-size: 16px;  line-height: 20px;  font-weight: 600; color: #505050; margin-bottom: 12px;opacity: 0.75;}
.videoInfoDiv p{font-family: 'outfit';  font-size: 14px;  line-height: 18px;  font-weight: 500; color: #505050; margin-bottom: 0px;opacity: 0.4;}
.videoInfoDiv .videoContent{margin-left: 10px;flex: 1;}
/* .videoImg{border-radius: 8px;width: 150px; height: 90px;} */
/* .videoImg img{object-fit: cover; width: 150px; height: 90px;} */
.searchByDays{display: flex; align-items: center;}
.searchByDays svg{color: #FD6F21; font-size: 13px;}
.menuRotate svg{transform: rotate(-180deg)}
/**/
.top-video-sec .player-icon{position: absolute;top: 0;display: none;align-items: center;justify-content: center;width: 100%;height: 100%;}
.top-video-sec .player-icon svg{fill: #fff;font-size: 30px;}
.top-video-sec ul li .videoInfoDiv:hover .player-icon {display: flex;}
/**/
.dashboardPage .dashboard-boxes {display: flex;justify-content: space-between;}
.dashboardPage .dashboard-boxes .box {min-height: 690px; margin: 0 15px;} 
.dashboardPage .dashboard-boxes .boxOne{width: 38%;min-width: 582px; margin-left: 0;}
.dashboardPage .dashboard-boxes .boxThree{margin-right:0;}
.dashboardPage .dashboard-boxes .boxTwo{width: 29%;min-width: 450px;}
.dashboardPage .dashboard-boxes .boxThree{width: 26%;min-width: 400px;}
.dashboardPage .dashboard-boxes .boxOne h2 {margin-bottom: 80px;}
/**/

/*responsiveness*/
@media (max-width: 1900px) {
  .dashboardPage .dashboard-boxes .boxOne{min-width: auto;}
  .dashboardPage .dashboard-boxes .boxTwo{min-width: auto;}
  .dashboardPage .dashboard-boxes .boxThree{min-width: auto;width: 28%;}
}
@media (max-width: 1799px) {
  .dashboardPage .dashboard-boxes .box {min-height: 560px;}
}
@media (max-width:1699px) {
.px40{padding-inline: 20px;}
.boxMb80 { margin-bottom: 40px;}
.box{ border-radius: 10px; padding: 20px;}
/* .dashboardHeader h2{ font-size: 20px;  line-height: 24px; } */
.analyticBox.box > h2 ,.analyticBox.box .analytics > div, .analyticBox.box > ul { margin-left: 20px; margin-right: 20px;}
.analyticBox.box .analytics .analyticSummary{margin: 0px 0px 20px 0px;}
.analytics p{ line-height: 22px; }
.upload .orange-btn { margin-top: 35px;}
.upload img { margin-bottom: 20px; width: 100px;}
.analyticSummary ul { padding: 0px 20px; margin: 15px 0px;}
.searchByDays svg{font-size: 10px;}
.analytics p.md-16{ font-size: 14px;  line-height: 18px;}
.analytics .analyticSummary p{ font-size: 14px;  line-height: 35px;}
.count{ font-size: 35px;  line-height: 29px;  margin-top: 25px;}
.wrapVideoInfo ul li .videoInfoDiv{padding: 10px 0px;}
.videoInfoDiv h4{ font-size: 14px;  line-height: 18px; margin-bottom: 6px;}
.videoInfoDiv p{ font-size: 12px;  line-height: 17px;}
.searchByDays svg{ font-size: 10px;}
/* .videoInfoDiv .videoImg{ max-width: 100px; height: 60px; } */
/**/
.dashboardHeader {margin-bottom: 30px;}
.dashboardPage .dashboard-boxes .box {min-height: 510px;}
}
@media (max-width:1199px) {
  .dashboardPage .dashboard-boxes {flex-wrap: wrap;}
  .dashboardPage .dashboard-boxes .box {margin:0 15px 30px 0;}
  .dashboardPage .dashboard-boxes .boxThree{margin-right:0;}
  .dashboardPage .dashboard-boxes .boxOne {width: 100%;}
  .dashboardPage .dashboard-boxes .boxTwo, .dashboardPage .dashboard-boxes .boxThree {width: 48%;}
  /**/
  .dashboardHeader {margin-bottom: 24px;}
}
@media (max-width:575px) {
/* .dashboardHeader h2, .dashboardHeader button{text-align: center; margin: auto;} */
.dashboardHeader {padding-top: 20px;}
.px40 {padding-inline: 10px;}
.dashboardPage .dashboard-boxes .box {min-height: auto;}
}
@media (max-width: 768px) {
  .dashboardPage .dashboard-boxes .boxTwo, .dashboardPage .dashboard-boxes .boxThree {width: 100%;}  
  .dashboardPage .dashboard-boxes .box:last-of-type {margin-bottom: 0px;}
  .dashboardPage .dashboard-boxes .box{margin:0 0 20px;}
}
@media (max-width: 479px){
  .dashboardHeader h2 {text-align: center;}
}