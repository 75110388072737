.mission-banner{background-image:url('../../../assets/images/people-working.png');background-size:cover;background-position: center center;position: relative;}
.mission-banner:after{content:"";inset:0;position:absolute;background:rgba(0,0,0,0.7);}
.mission-banner .container{position: relative;z-index:1}
.overview-sec {
  padding: 165px 0;
}
.overview-sec h1 {
  font-weight: 600;
}

.overview-sec h3 {
  font-size: 30px;
  color: #B4B4B4;
  font-weight: 300;
  /* opacity: 0.75; */
  font-family: "SofiaPro";
}

.together-sec {
  position: relative;
  padding: 100px 0;
}

.together-sec h1 {
  font-weight: 600;
}

.together-sec h3 {
  font-size: 30px;
  line-height: 46px;
  font-weight: 500;
  color: #fff;
}

.together-sec p {
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  line-height: 38px;
}

.together-sec .intro-row {
  margin: 150px 0 200px 0px;
}

.content-page .content-section{padding:100px 0;}
.together-sec .wrapper h4 {
  font-weight: 700;
  margin-bottom: 45px;
  line-height: 53px;
  color:#fff;
}

.together-sec .wrapper p {
  font-weight: 300;
  font-family: "SofiaPro";
}

.together-sec .reviews p {
  opacity: 0.6;
  font-weight: 400;
  margin-bottom: 20px;
  max-width: 350px;
}

.together-sec .reviews h1 {
  font-size: 135px;
  font-weight: 600;
  line-height: 140px;
}

.team-sec {
  padding: 120px 0;
  background-color: #F2F3F8;
}

.team-sec h1,
.join-sec h1 {
  margin-bottom: 100px;
  color:#505050;
}

.team-sec .team {
  width: 20%;
  margin-bottom: 40px;
}

.team-sec .team .img {
  margin-bottom: 30px;
  height: 296px;
  border-radius: 20px;
  overflow: hidden;
}

.team-sec .team .img img {
  object-fit: cover;
  width:100%;height:100%;
}

.team-sec .team .team-name {
  font-weight: 600;
  font-size: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.team-sec .team .team-designation {
  font-weight: 400;
  font-size: 16px;
  color: #B4B4B4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color:#505050;
}

.join-sec {
  padding: 120px 0;
  background-color: #F2F3F8;
  color:#505050;
}
.joinBtn {
  text-decoration: none;
  color: #fff !important;
  padding-bottom: 5px;
}

.joinBtn.link::before {
  transform: scaleX(1);
}

.joinBtn.link:hover:before {
  transform: scaleX(0);
}

.join-sec p {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 50px;
  line-height: 38px;
}
.join-sec .orange-main-button{padding:15px 35px;font-size:16px;line-height:20px;color:#fff;border-radius:10px;font-weight:700;}
.join-sec .orange-main-button:hover{color:#FD6F21;}
.help-sec {
  padding: 150px 0;
}

.help-sec h1 {
  margin-bottom: 80px;
}

.help-sec .no-faqs {font: normal normal 600 24px/30px Outfit; color: #B4B4B4;}

.help-sec .accordion-button,
.help-sec .accordion-item {
  background-color: transparent;
  color: #fff;
}

.help-sec .accordion-button {
  box-shadow: none;
  padding: 25px 0 25px 10px;
  font-size: 24px;
  font-weight: 600;
  justify-content: flex-start;
  color: #FC491E;  border-radius:0;
  transition: all ease-in-out 0.3s;
  margin:0 0 10px;
  background:#f1f1f1;
}

.help-sec .accordion-button.collapsed{
  color: #FD6F21;
}
.help-sec .accordion-button:hover{
  color: #FC491E;
  background: rgba(253, 111, 33,0.1);
}

.help-sec .accordion-item {
  border-radius: 0px;
  border: 0;
  border-bottom: 1px solid rgba(225, 225, 225, 0.25);
}

.help-sec .plus-icon,
.help-sec .minus-icon {
  padding-right: 40px;
}

.help-sec .accordion-button::after {
  display: none;
}

.help-sec .accordion-button.collapsed .plus-icon {
  display: unset;
}

.help-sec .accordion-button .plus-icon {
  display: none;
}

.help-sec .accordion-button.collapsed .minus-icon {
  display: none;
}

.help-sec .accordion-button .minus-icon {
  display: unset;
}

.help-sec .accordion-body {
  color: #B4B4B4;
  font-size: 20px;
  font-weight: 400;
  padding-left: 64px;
  border-left:4px solid #FD6F21;
}

.help-sec .accordion-body p {
  padding-left: 20px;
}

.help-sec .form-wrapper {
  border-radius: 30px;
  box-shadow: 0px 7px 20px #0000001c;
  background-color: #ffffff;
  padding: 60px;
  max-width: 650px;
  margin: 0 auto 30px;;
}

.help-sec .form-wrapper h2 {
  color: #0d0f17;
}

.help-sec .form-wrapper p {
  color: rgba(13, 15, 23, 0.4);
  font-size: 20px;
  font-weight: 400;
  max-width: 70%;
  margin: auto auto 40px auto;
  text-align: center;
}

.help-sec .form-wrapper .form-label {
  font-size: 20px;
  color: #808188;
  font-weight: 500;
  line-height: 25px;
}

.help-sec .form-wrapper .form-control {
  background-color: transparent !important;
  border: 1px solid rgba(13, 15, 23, 0.25);
  font-size: 20px;
  color: #212529;
  padding: 17px 12px;
  height: unset;
}

.help-sec .form-wrapper input[type="email"] {
  height: unset;
}

.help-sec .form-wrapper textarea {
  border: 1px solid rgba(13, 15, 23, 0.25);
  font-size: 20px;
  color: #212529;
  border-radius: 12px;
  padding: 6px 12px !important;
  resize: none;
  height: 120px;

}

.help-sec .form-wrapper textarea:focus-visible {
  border: 1px solid rgba(13, 15, 23, 0.25);
  outline: none;
}

.submit-btn button {
  border: 1px solid #FD6F21;
  border-radius: 10px;
  opacity: 1;
  color: #FD6F21;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 40px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

.submit-btn button:hover {
  border: 1px solid #FD6F21;
  color: #fff;
  background-color: #FD6F21;
}
.aboutImg {
  width: 100%;
}
.together-sec .bar {
  position: absolute;
  bottom: -8%;
  width: 320px;
  height: 100%;
  transform: skew(-46deg);
  overflow: hidden;
  background: transparent linear-gradient(174deg, #0d0f17 0%, #fd6f21 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  left: 130px;
  z-index: -1;
}
.submit-btn button:active{color: #fff !important;background-color: #FD6F21 !important;}
.together-sec .col-lg-4 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.reviews .reviews-wrapper{display: flex;align-items: center;justify-content: center;flex-direction: column;}
.reviews .reviews-wrapper h1{font-size: 100px;line-height: 110px;}
.reviews .reviews-wrapper p {color: #505050;}

/**/
.reviews {padding: 150px 0px;}
.content-page .border-heading{font-size:53px;line-height:60px;padding:20px;border-left:5px solid #FD6F21;border-radius:7px;background:rgba(253, 111, 33,0.07);font-weight:600;}

/* Media Quries */
@media (max-width: 1699px) {
  .submit-btn button {
    font-size: 16px;
    line-height: 20px;
    min-height: auto;
    padding: 16px 35px;
  }
  .overview-sec h3 {
    font-size: 26px;
  }
  .together-sec p {
    font-size: 21px;
    line-height: 34px;
  }
  .join-sec p {
    font-size: 22px;
    line-height: 32px;
  }
  .help-sec .no-faqs {
    font: normal normal 600 22px/28px Outfit;
  }
}
@media (max-width: 1599px) {
  .overview-sec,
  .together-sec {
    padding: 100px 0;
  }
  .overview-sec h3,
  .together-sec h3 {
    font-size: 22px;
    line-height: 26px;
  }
  .together-sec p {
    font-size: 22px;
    line-height: 32px;
  }
  .together-sec .wrapper h4 {
    margin-bottom: 30px;
  }
  .together-sec .intro-row {
    margin: 100px 0;
  }
  .together-sec .wrapper {
    padding: 50px 40px;
  }
  .together-sec .reviews h1 {
    font-size: 100px;
    line-height: 110px;
  }
  .team-sec h1 {
    margin-bottom: 70px;
  }
  .team-sec .team .team-name {
    font-size: 24px;
  }
  .team-sec .team .team-designation {
    font-size: 18px;
  }
  .team-sec .team .img {
    margin-bottom: 20px;
  }
  .join-sec p {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  .join-sec a {
    font-size: 26px;
  }
  .help-sec h1 {
    margin-bottom: 50px;
  }
  .help-sec .accordion-button {
    font-size: 22px;
    line-height: 25px;
  }
  .help-sec .accordion-body {
    font-size: 18px;
  }
  .submit-btn button {
    padding: 15px 40px;
    font-size: 14px;
    line-height: 18px;
    border-radius: 5px;
  }
  .help-sec .form-wrapper {
    padding: 50px;
  }
  .help-sec .form-wrapper p {
    font-size: 18px;
  }
  .team-sec,
  .join-sec,
  .help-sec {
    padding: 100px 0;
  }
  .help-sec .form-wrapper .form-label {
    font-size: 18px;
  }
  .team-sec .team .img {
    height: 300px;
  }
  .help-sec .form-wrapper .form-control {
    padding: 15px 12px;
  }
  .together-sec .bar {
    width: 195px;
    transform: skew(-48deg);
  }
  .reviews .reviews-wrapper h1{font-size: 80px;line-height: 90px;}
  .reviews {padding: 100px 0;}
  .content-page .content-section{padding:80px 0;}
  .content-page .border-heading{font-size:40px;line-height:45px;}
  .team-sec h1,.join-sec h1{margin-bottom: 50px;}
}

@media (max-width: 1399px) {
  .team-sec .team {
    width: 25%;
  }
  .help-sec .plus-icon,
  .help-sec .minus-icon {
    padding-right: 20px;
  }
  .help-sec .accordion-body {
    padding-left: 44px;
  }
  .help-sec .accordion-body p {
    padding-left: 15px;
  }
  .help-sec .form-wrapper {
    max-width: 450px;
    padding: 40px;
    border-radius: 25px;
  }
  .help-sec .form-wrapper p {
    margin-bottom: 20px;
    max-width: 85%;
  }
  .overview-sec,
  .together-sec {
    padding: 70px 0;
  }
  .together-sec .intro-row {
    margin: 70px 0;
  }
  .team-sec,
  .join-sec,
  .help-sec {
    padding: 70px 0;
  }
  .team-sec h1 {
    margin-bottom: 50px;
  }
  .help-sec .form-wrapper .form-control {
    padding: 14px 12px;
  }
  .together-sec p {
    font-size: 18px;
    line-height: 23px;
  }
  .reviews .reviews-wrapper h1{font-size: 60px;line-height: 70px;}
  .reviews {padding: 50px 0;}
  /**/
  .overview-sec h1 {
    font-size: 38px;
    line-height: 46px;
  }
  .submit-btn button {
    padding: 14px 40px;
  }
  .content-page .content-section{padding:50px 0;}
  .content-page .border-heading{font-size:34px;line-height:40px;}
  .team-sec h1,.join-sec h1{margin-bottom: 30px;}
}

@media (max-width: 1199px) {
  .overview-sec,
  .together-sec {
    padding: 70px 0;
  }
  .overview-sec h3,
  .together-sec h3 {
    font-size: 24px;
  }
  .together-sec .intro-row {
    margin: 70px 0;
  }
  .together-sec .wrapper {
    padding:0;
  }
  .together-sec .wrapper h4 {
    margin-bottom: 20px;
  }
  .together-sec .wrapper h4 {
    font-size: 22px;
  }
  .together-sec .reviews h1 {
    font-size: 80px;
    line-height: 90px;
  }
  .team-sec {
    padding: 70px 0;
  }
  .team-sec h1 {
    margin-bottom: 50px;
  }
  .team-sec .team {
    margin-bottom: 20px;
  }
  .join-sec {
    padding: 70px 0;
  }
  .team-sec .team .img {
    height: 260px;
  }
  .help-sec .form-wrapper .form-control {
    padding: 12px 12px;
  }
  .overview-sec h1 {
    font-size: 31px;
    line-height: 41px;
  }
  .content-page .content-section{padding:50px 0;}
}

@media (max-width: 991px) {
  .overview-sec h1,
  .together-sec h1{
    margin-bottom: 20px !important;
  }
  .together-sec .reviews h1 {
    text-align: center;
  }
  .team-sec .team {
    width: 33.3%;
  }
  .help-sec .accordion {
    margin-bottom: 20px;
  }
  .overview-sec,
  .together-sec {
    padding: 50px 0;
  }
  .reviews {
    padding: 30px 0;
  }
  .together-sec .intro-row {
    margin: 50px 0;
  }
  .team-sec {
    padding: 50px 0;
  }
  .join-sec {
    padding: 50px 0;
  }
  .team-sec,
  .join-sec,
  .help-sec {
    padding: 50px 0;
  }
  .together-sec .reviews h1 {
    margin: auto;
  }
  .together-sec .reviews p {
    margin: auto auto 20px auto;
    text-align: center;
  }
  .help-sec .form-wrapper {
    max-width: 550px;
  }
  .content-page .content-section{padding:30px 0;}
  .content-page .border-heading{font-size:26px;line-height:32px;}
  .team-sec h1,.join-sec h1{margin-bottom: 20px;}
}

@media (max-width: 767px) {
  .team-sec .team {
    width: 50%;
  }
  .team-sec .team .img {
    height: 290px;
  }
  .overview-sec h1 {
    font-size: 27px;
    line-height: 37px;
  }
  .submit-btn button {
    padding: 12px 25px;
  }
}

@media (max-width: 575px) {
  .overview-sec,
  .together-sec {
    padding: 30px 0;
  }
  .overview-sec h3,
  .together-sec h3 {
    font-size: 22px;
  }
  .together-sec .intro-row {
    margin: 30px 0;
  }
  .together-sec .reviews h1 {
    font-size: 60px;
    line-height: 70px;
  }
  .team-sec {
    padding: 50px 0;
  }
  .team-sec .team {
    width: 100%;
  }
  .team-sec,
  .join-sec,
  .help-sec {
    padding: 30px 0;
  }
  .join-sec p {
    font-size: 20px;
    line-height: 26px;
  }
  .join-sec a {
    font-size: 22px;
    line-height: 25px;
  }
  .join-sec p {
    margin-bottom: 20px;
  }
  .help-sec h1 {
    margin-bottom: 20px;
  }
  .help-sec .accordion-button {
    font-size: 20px;
    line-height: 23px;
  }
  .help-sec .accordion-body {
    font-size: 16px;
    line-height: 22px;
  }
  .help-sec .form-wrapper p {
    font-size: 16px;
    line-height: 22px;
  }
  .help-sec .form-wrapper {
    padding: 25px;
  }
  .help-sec .form-wrapper .form-label {
    font-size: 16px;
  }
  .team-sec .team .img {
    height: 370px;
  }
  .team-sec h1 {
    margin-bottom: 30px;
  }
  .team-sec .team .team-name {
    margin-bottom: 0 !important;
  }
  .help-sec .accordion-body p {
    padding-left: 7px;
  }
  .help-sec .form-wrapper .form-control {
    padding: 10px 12px;
  }
  .reviews .reviews-wrapper h1{font-size: 50px;line-height: 60px;}
  /**/
  .overview-sec h1 {
    font-size: 28px;
    line-height: 34px;
  }
  .content-page .border-heading{font-size:22px;line-height:28px;}
}

@media (max-width: 479px) {
  .submit-btn button {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 267px) {
  .overview-sec,
  .together-sec {
    padding: 50px 0 30px 0px;
  }
}

@media (max-width: 319.98px) {
  
}

.faqs-content img {
  width: 50px;
  height: 50px
}
