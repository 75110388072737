.package-card{background: #fff ; border-radius: 20px; padding:30px;text-align: left;color:#505050;border:1px solid #DBDBDB;}
.package-card h3{font-family: 'outfit'; font-size: 24px; line-height: 30px; font-weight: 700; color: #FC491E; margin-bottom: 30px;}
.package-ul{margin-bottom: 32px; padding-left: 0px; list-style: none;}
.package-ul li:not(:last-child){margin-bottom:10px;}
.package-ul li p{font-family: 'outfit'; font-size: 20px; line-height: 30px; font-weight: 400; margin-bottom:10px; color: #505050; margin-left: 15px;}
.package-ul li{display: flex; align-items: flex-start;}
.package-ul li::before{content: ''; display: block; width: 10px; height: 1px; background-color: #505050;margin-top: 13px;}
/**/
.card-details-sec{height:auto;padding:120px 0;}
.card-details-sec .custom-card-width{max-width: 1600px;margin: 0 auto;}
.card-details-sec .card-form{padding: 40px;background-color: #fff;border-radius: 20px;}
.card-details-sec .biiview-form{overflow-y: visible;height: auto;}
/**/
.card-details-sec .card-form .theme-capcha button {margin-top: 22px;max-height: 72px;}
.card-details-sec .inputDiv input{color:#212529;}
@media(max-width:1599px){
    .package-card{padding: 30px 20px;}
}
@media (max-width:1590px){
    .card-details-sec{padding:100px 0;}
}
@media (max-width:1399px) {
    .card-details-sec{padding:80px 0;}
    .package-card h3 { font-size: 18px; line-height: 22px; margin-bottom: 20px;}
    .package-ul li p { font-size: 16px; line-height: 20px; margin-left: 10px;}
    .package-ul li::before{margin-top: 8px;}
    .package-ul li::before { width: 6px;}
}
@media (max-width:767px) {
    .card-details-sec{padding:50px 0;}
    .custom-card-width .col-md-6 {margin-bottom: 12px;}
}
@media (max-width:575px) {
    .card-details-sec .card-form, .package-card {padding: 20px;}
}
@media (max-width:319px) {
    .date-cvc {flex-direction: column;}
}