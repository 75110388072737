.checkTable input, .checkTable .form-check-input[type=checkbox]{border: 1px solid #505050; border-radius: 6px; opacity: 0.5; background-color: transparent !important;}
table .checkTable input, table .checkTable .form-check-input[type=checkbox]{ width: 28px; height: 28px;}
.videoInfo{display: flex; }
.videoIcons{display: flex; align-items: center;}
.videoIcons{margin-top: 21px;}
.videoInfo .videoImg{width: 150px; height: 100px; margin-right: 20px;min-width: 150px;max-width: 150px;}
.videoInfo .videoImg img {width: 100%; height: 100%; object-fit: cover; border-radius: 8px;}
.videoInfo > img{ object-fit: cover;}
.themeTable table td .videoInfo p {font-weight: 600;}
.themeTable table td .videoInfo span.description{font-size: 16px;color:#505050;line-height: 18px;}
.themeTable table td .videoInfo span.description .orangeLinkBtn {min-height: unset;min-width: unset;display: inline-block;margin-left: 7px;font-size: inherit;}
.videoIcons > span:not(:last-child){margin-right: 30px;}
.analyticGraph{background: #fff; border-radius: 20px;}
.analyticsBar{display: flex; border-bottom: 1px solid #E1E1E1;}
.analyticsBar .analyticsBarBox{padding: 35px; text-align: center; width: 100%;}
.analyticsBar .analyticsBarBox:first-child{border-top-left-radius: 20px;}
.analyticsBar .analyticsBarBox:not(:last-child){border-right: 2px solid #E1E1E1;}
.analyticsBar .analyticsBarBox span{font-family: 'Outfit'; font-size: 16px; line-height: 20px; font-weight: 500;opacity: 0.4;}
.analyticsBar .analyticsBarBox h4{font-family: 'Outfit'; font-size: 30px; line-height: 38px; font-weight: 500;margin-bottom: 0px;}
.realtimeBox{padding-bottom: 70px; border-bottom: 1px solid #E1E1E1;max-width: 213px;}
.box .realtimeBox {max-width: 100%;}
.realtimeBox h5{font-family: 'Outfit'; font-size: 24px; line-height: 30px; font-weight: 500;margin-bottom: 6px;}
.realtimeBox p{font-weight: 500;line-height: 20px;}
.realtimeBox-btm{ display: flex; justify-content: space-between; padding: 20px 0px 5px 0px;}
.videoIcons{margin-top: 21px;}
.videoInfo .videoImg{width: 150px; height: 100px;margin-right: 20px;}
.videoInfo > img{ object-fit: cover;}
.videoIcons > .iconBtn{ padding: 0px; background: transparent; color: #000000; border: 0px; font-size: inherit;min-height: unset;}
.videoIcons > span:not(:last-child) ,.videoIcons > .iconBtn:not(:last-child) {margin-right: 30px;}
.videoIcons > .iconBtn:hover {color: #FD6F21;}
.videoIcons > .iconBtn:focus-visible{outline: none;}
.videoIcons > .iconBtn.disabled-icon {color: #FD6F21 !important;opacity: 0.3;}
/*responsiveness*/
@media (max-width:1699px) {
.checkTable input, .checkTable .form-check-input[type=checkbox]{border-radius: 4px; }
table .checkTable input, table .checkTable .form-check-input[type=checkbox]{width: 18px; height: 18px;}
.videoIcons{margin-top: 21px;}
.videoInfo .videoImg{width: 120px; height: 90px; margin-right: 10px;}
.videoIcons > span:not(:last-child){margin-right: 30px;}
.analyticGraph{border-radius: 8px;}
.analyticsBar .analyticsBarBox{padding: 35px; text-align: center; width: 100%;}
.analyticsBar .analyticsBarBox:first-child{border-top-left-radius: 10px;}
.analyticsBar .analyticsBarBox span{font-size: 14px; line-height: 18px; }
.analyticsBar .analyticsBarBox h4{font-size: 24px; line-height: 28px; }
.realtimeBox{padding-bottom: 70px; border-bottom: 1px solid #282C3C;}
.realtimeBox h5{font-size: 18px; line-height: 24px; margin-bottom: 6px;}
.realtimeBox p{font-weight: 500;line-height: 20px;}
.realtimeBox-btm{ display: flex; justify-content: space-between; padding: 20px 0px 5px 0px;}
}
@media (max-width:1399px){
    .realtimeBox {margin: 0 0 30px auto;}
}
@media (max-width:776px) {
.videoInfo .videoImg{width: 90px; height: 85px;}
.analyticsBar{flex-wrap: wrap;border-bottom: 0px;}
.analyticsBar .analyticsBarBox{padding: 25px;}
.analyticsBar .analyticsBarBox:first-child { border-top-left-radius: 10px; border-top-right-radius: 10px;}
.analyticsBar .analyticsBarBox:not(:last-child) { border-right: 0px solid #282C3C;}
.analyticsBar .analyticsBarBox:not(:last-child){border-bottom: 1px solid #282C3C;}
}