.feature{position: relative;z-index: 2;}
.feature .customBox { padding: 0 36px;}
.feature .data-class-block,
.feature .data-class-block .customBox,
.feature .data-class-block .featureRow{height:777px;}
.feature .data-class-block .featureImg{width:1054px;position: absolute;top:0;right:0;}
.classify ul{display: flex; margin-bottom: 0px; padding-left: 0px;list-style: none;}
.classify ul li{display:flex;}
.classify ul li:not(:last-child){margin-right: 62px;}
.classify ul li svg{margin-right: 5px; font-size: 30px; line-height: 0px; color: #FD6F21;}
.classify ul li p{font-family: 'outfit'; font-size: 20px; line-height: 31px; font-weight: 600;opacity: 0.75;}
.featureImg{ position: relative; z-index: 9999;}
.feature .bar{position: absolute; bottom: -13%; width: 290px; height: 95%; transform: skew(-48deg); overflow: hidden; background: transparent linear-gradient(174deg, #0D0F17 0%, #FD6F21 100%) 0% 0% no-repeat padding-box; opacity: 1; left: 4px;}
.classify{position:relative; z-index: 99;}
.classify p {max-width: 705px;}
.wrapData p {max-width: 642px;}
.wrapData .orange-btn{margin-top: 40px;}
.wrap-path-lines{margin-right: auto; width: 100%; display: flex; justify-content: flex-start; margin-top: 150px;}
.wrap-path-lines .lines, .wrap-path-lines .line3{ display: inline-block; height: 150px; width: 1px; border-left: 1px dashed rgb(143 143 143 / 40%);}
.wrap-path-lines .lines{ width: 50%;}
.wrap-path-lines .line2{ position: relative; display: block; width: 100%; height: 2px; border-top: 1px dashed rgb(143 143 143 / 40%);}
.wrap-path-lines .line3{ position: absolute; right: 0; bottom: 0;}
.dots{display: flex; flex-direction: column;position: absolute;}
.dots span:not(:last-child){margin-bottom: 27px;}
.dots span{display: block; width: 8px; height: 8px; background-color: #fff; border-radius: 50%;z-index: 999;}
/**/
.feature .sectionTitle .sofia-p {margin-bottom: 50px;}
.feature .data-label-block{padding:160px 0;position: relative;z-index:2;}
.feature .data-label-block .dataLabelRow{height:936px;}
.feature .data-label-block:after{width:100%;height:calc(100% + 90px);content:"";position: absolute;right:0;bottom:0;background-color:#F8F8F8;background-image: url('../../../../assets/images/img-data-label-bg.png');background-size:cover;background-position: 100% 100%;z-index:-1}
.feature .data-label-block .dataLabelImg{width:1042px;position: absolute;left:0;top:0;}
/*media quries*/
@media (max-width:1899px){
	.feature .data-class-block,
	.feature .data-class-block .customBox,
	.feature .data-class-block .featureRow{height:652px;}
	.feature .data-class-block .featureImg{width:885px;}
	.feature .data-label-block .dataLabelImg{width:882px;}
	.feature .data-label-block .dataLabelRow{height:795px;}
}
@media (max-width:1699px) {
	.wrapData h2{margin-bottom: 16px;}
	.wrapData .orange-btn{margin-top: 30px;}
	.classify .orange-btn {margin-top: 30px;}
}
@media (max-width:1599px) {
	.feature .data-class-block,
	.feature .data-class-block .customBox,
	.feature .data-class-block .featureRow{height:635px;}
	.feature .data-class-block .featureImg{width:860px;}
	.feature .data-label-block{padding:100px 0;}
	.feature .data-label-block .dataLabelImg{width:810px;}
	.feature .data-label-block .dataLabelRow{height:727px;}
}
@media (max-width:1499px){
	.feature .data-class-block .featureImg {width:750px;}
	.feature .data-class-block,
	.feature .data-class-block .customBox,
	.feature .data-class-block .featureRow{height:555px;}
	.feature .data-label-block .dataLabelImg {width:660px;}
	.feature .data-label-block .dataLabelRow{height:600px;}
}
@media (max-width:1399px) {
	.feature .customBox { padding: 0 25px;}
	.wrap-path-lines .lines, .wrap-path-lines .line3 {  height: 95px;}
	.wrap-path-lines{margin-top: 95px;}
	.mainF-Row1{padding: 0px 0px 100px 0px;}
	.classify ul li:not(:last-child) { margin-right: 20px;}
	.classify ul li p {font-size: 14px; line-height: 19px;}
	.classify ul li svg { margin-right: 3px; font-size: 20px;}
	.dots span{width: 6px; height: 6px;}
	.dots span:not(:last-child) { margin-bottom: 17px;}
	.feature .bar{ width: 145px; transform: skew(-42deg); left: -23px;}
	/**/
	.feature .data-label-block{padding:80px 0;}
	.feature .data-class-block .featureImg,
	.feature .data-label-block .dataLabelImg{width:600px;}
	.feature .data-class-block,
	.feature .data-class-block .customBox,
	.feature .data-class-block .featureRow{height:445px;}
	.feature .data-label-block .dataLabelRow{height:540px;}
}
@media (max-width:1199px) {
	.wrapData { margin-left: -100px; }
	.wrap-path-lines .lines, .wrap-path-lines .line3 {  height: 65px;}
	.wrap-path-lines{margin-top: 65px;}
	.feature .data-label-block .dataLabelImg{width:470px;}
	.feature .data-label-block .dataLabelRow{height:425px;}
}
@media (max-width: 991px) {
	.feature .dataLabelImg {margin-left: 0px;}
	.feature .dots {display: none;}
	.feature .wrapData {margin-left: 0px;}
	.feature .customBox {padding: 0 12px;}
	.feature .data-class-block .featureImg,
	.feature .data-label-block .dataLabelImg{position: relative;top: auto;right: auto;z-index: 2;margin: 0 auto 15px;width: 80%;border-radius: 10px;overflow: hidden;}
	.feature .data-class-block,
	.feature .data-class-block .customBox,
	.feature .data-class-block .featureRow,
	.feature .data-label-block .dataLabelRow{height:auto;}
}
@media (max-width:767px) {
	.dots { top: -100px;}
	.wrap-path-lines .lines, .wrap-path-lines .line3 {  height: 55px;}
	.wrap-path-lines{margin-top: 55px; width: 100%;}
	.feature .bar { left: -65px;}
}
@media (max-width:467px) {
	.classify ul{    flex-direction: column; justify-content: center;  width: 100%; margin: auto;}
	.classify ul li{margin: auto auto 10px auto; margin-right: auto;}
	.classify ul li:not(:last-child) { margin-right: auto;}
	/* .wrap-path-lines .line2 {width: 180px; } */
	.wrap-path-lines .lines, .wrap-path-lines .line3 { height: 66px;}
	.wrap-path-lines {top: -69px;}
	.wrap-path-lines .lines, .wrap-path-lines .line3 {  height: 35px;}
	.wrap-path-lines{margin-top: 35px;}
	.wrap-path-lines{ justify-content: left; width: 100%;}
}
