#under-construction {height:calc(100vh - 80px);padding: 0 12px;}
#under-construction .under-construction {max-width:768px;width: 100%;line-height: 1.4;text-align: center;}
#under-construction .img-holder{margin:0 auto 30px;}
#under-construction h1 {font-family: sans-serif;font-size:90px;line-height:96px;font-weight: 700;margin: 0;color: #FD6F21;}
#under-construction .orange-main-button{padding:22px 48px;border:2px solid #FD6F21;border-radius:10px;color:#fff;font-size:18px;line-height:22px;margin:0; transition: all 0.3s ease-in-out;}
#under-construction .orange-main-button:hover{color: #FD6F21;}
@media (max-width: 1024px) {
    #under-construction .under-construction .under-construction-404 h1{font-size:70px;line-height:80px;}    
}

@media (max-width: 575px) {
    #under-construction .under-construction .under-construction-404 h1 {
        font-size: 140px;
        line-height: 145px;
        letter-spacing: 0;
    }
    #under-construction .under-construction h2 {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (max-width: 319px) {
    #under-construction .under-construction .under-construction-404 h1 {
        font-size: 120px;
        line-height: 125px;
    }
}
