/* .mainSidebar {position: fixed; top: 125px;  height: 80vh; z-index: 999; margin: 30px 0px 30px 34px;}
.sidebarFlex{display: flex; flex-direction: column; justify-content: space-between; min-height: 100%; height: 80vh; overflow-y: auto; background: #151721; border-radius: 40px; padding-bottom: 40px; min-width: 294px;}
.sidebarStyle.w2{min-width: 90px;}
.sidebarStyle.w2 ul{border-bottom: 0px; padding-top: 50px;}
.sidebarStyle ul li{margin-bottom: 10px;}
.sidebarStyle.w2 ul li a{ padding: 0px 0px 0px 13px; margin-right: 10px;}
.sidebarStyle.w2 ul li a span{padding-left: 5px; margin-right: 10px;}
.sidebarStyle.w2 ul li:not(:last-child) a span { border-bottom: 0px solid #303341; }
.sidebarStyle h3{ font-family: 'outfit';  font-size: 24px;  line-height: 30px;  font-weight: 700; padding: 30px 0px 30px 30px; color: #fff; text-transform: capitalize;}
.sidebarStyle ul{padding-left: 0px; margin-bottom: 0px; list-style: none;border-bottom: 1px solid #303341;}
.sidebarStyle ul li a{display: block; font-family: 'outfit';  font-size: 16px;  line-height: 25px;  font-weight: 500; padding: 0px 0px 0px 43px; color: #fff; text-transform: capitalize; text-decoration: none; margin-right: 29px; border-radius: 0px 25px 25px 0px; transition: all ease-in-out .4s;}
.sidebarStyle ul li a span { display: inline-block; padding: 15px 0px 15px 0px; width: 100%; padding-left: 15px;}
.sidebarStyle ul li:not(:last-child) a span { border-bottom: 1px solid #303341; }
.sidebarStyle ul li a:hover, .sidebarStyle ul li a.active{background: #FD6F21;}
.sidebarStyle ul li a:hover span, .sidebarStyle ul li a.active span{border-color: transparent;}
.sidebarStyle .orange-btn{margin-left:43px;} */


/* .sidebarLogo{float: right; font-size: 22px; color: #FD6F21; cursor: pointer; margin: -20px; width: 65px; height: 65px; border-radius: 50%; background: #FD6F2180; display: flex; align-items: center; justify-content: center; transition: all ease-in-out .4s; transition-delay: .2s;} */


/* .sidebarLogo{float: right; font-size: 22px; color: #fff; cursor: pointer; margin: -20px; width: 65px; height: 65px; border-radius: 50%; background: #FD6F21; display: flex; align-items: center; justify-content: center; transition: all ease-in-out .4s; transition-delay: .2s;}
.rotate{transform: rotate(-135deg);}
.logo-change{transform: rotate(90deg);}
.mainSidebar .orange-btn{transition: all ease-in-out .2s;}
.logout{background-color: transparent; border:0px; transition: all ease-in-out 0s;}
.logout svg{ font-size: 35px; color: #fff;}
.sitePages{float: right;padding: 30px 30px 59px 30px;}
.sitePages-main-width{width: calc(100% - 294px);}
.sitePages-width{width: calc(100% - 90px);} */
.mobileMemberImg{width: 50px; height: 50px; border-radius: 50%;margin: 20px auto;}
.mobileMemberImg img, .memberImg.mobileMemberImg img{width:100%; height:100%; max-width: 100%; object-fit: cover;}

/* @media (max-width:1699px) {
.mainSidebar{top: 91px;margin: 25px 0px 25px 30px;}
.explore{padding-top: 105px;}
.sidebarStyle h3{ font-size: 18px; line-height: 24px; padding: 20px 0px 20px 20px;}
.sidebarStyle ul li a { font-size: 14px; line-height: 20px; padding: 0px 0px 0px 15px;}
.sidebarStyle ul li a span { padding: 11px 0px 11px 0px; padding-left: 13px;}
.sidebarStyle ul li a img{width: 15px;}
.sidebarLogo{width: 45px; height:45px;}
.sidebarLogo svg{width: 15px;}
.sidebarFlex {min-width: 220px;}
.memberImg img{max-width: 150px;}
} */
/* @media (max-width:1399px) {
.sidebarStyle h3{ font-size: 16px; line-height: 20px; padding: 15px 0px 15px 15px;}
.sidebarLogo{width: 35px; height:35px; margin: -10px -17px;}
.sidebarLogo svg{width: 10px;}
.sitePages-main-width { width: calc(100% - 250px);}
.sitePages { padding: 20px 20px 35px 20px;}
.sidebarStyle.w2{min-width: 50px;}
.logout svg { font-size: 24px;}
} */
@media (max-width:992px) {
/* .sitePages-main-width { width: calc(100% - 50px);}
.sidebarFlex{width: 0px;} */
.mobileMemberImg{margin: auto;}
 }





