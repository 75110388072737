.v-custom-player{position: relative;}
.v-custom-player video{border-radius: 20px;}
.video-site-content h2{font-size: 32px;line-height: 48px;color:#505050;font-weight: 700;margin-bottom: 15px;}
.video-site-content .no-of-views{color:#505050;opacity: 0.6;font-size:16px;line-height:20px;}
.v-user-details{padding: 20px 0;margin-bottom: 30px;}
.video-site-content .user-name{font-size: 18px;line-height: 22px;font-weight: 500;color:#505050;}
.video-site-content .publish-date{font-size: 14px;line-height: 17px;font-weight: 400;color:#505050;opacity: 0.6;}
.v-description p{color: #FD6F21;opacity: 1;position: relative;font-weight: 500;}
.v-description p:after{content: '';position: absolute;left:0;bottom: -10px; width: 30px; height: 2px; background-color: #FD6F21;}
.v-description span{font-size: 14px;line-height: 24px;font-weight: 400;margin-bottom: 20px;display: block;}
.v-custom-sitecards .exploreCard{margin-bottom: 40px;}
.v-custom-sitecards .exploreCard .make-video-favorite button{border:none;color:#f00;font-size:24px;display:flex;align-items: center;}
.v-custom-sitecards .exploreCard .make-video-favorite .count,
.video-site-content .make-video-favorite .count{margin:0;font-size:20px;line-height:24px; font-weight: 500;margin:0 0 0 5px;color:#f00;}
.v-custom-player{overflow: hidden; margin: 0 0 30px;height: 540px;}
.right.dashboard-sitePages .v-custom-player{height: 610px;}
.sitePages-width .v-custom-player{height: 610px;}
.custom-controls .vjs-control-bar{display: none;}
.video-js{width: 100%;max-height: 50vh;background:none;}
.recomemded-videos .section-heading{margin:0 0 40px;font-size:24px;line-height:28px;color:#000;font-weight:700;}
.recomemded-videos .exploreCard:hover .player-icon{position: absolute;top: 0;left: 0;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;}
.recomemded-videos .player-icon svg{fill: #fff;}
.recomemded-videos .exploreCard:hover .video-img {opacity: 0.5;}
.object-visit-btn{min-height: unset;padding: 0 !important;}
/**/
/* #custom-player {height: 540px;margin: 0 0 30px;}
#custom-player .video-js {width: 100%;height: 100%;object-fit: contain;max-height: initial;} */
/**/
.v-player-container {height: calc(100vh - 60px);margin-bottom: 0;}
.v-player-container .v-custom-sitecards h2 {margin: 0 0 30px;}
/**/
.v-player-container .video-detail-section {display: flex;justify-content: space-between;}
.v-player-container .video-detail-section .video-area {min-width: 960px;width: 66%;max-height: 100%;overflow-x: hidden;overflow-y: auto;-ms-overflow-style: none;  scrollbar-width: none;  }
.v-player-container .video-detail-section .video-area::-webkit-scrollbar {display: none;}
.v-player-container .video-detail-section .video-content {width: 33%;min-width: 512px;padding:0 20px 20px;max-height: 100%;overflow-x: hidden;overflow-y: auto;-ms-overflow-style: none;  scrollbar-width: none;}
.v-player-container .video-detail-section .video-content::-webkit-scrollbar {display: none;}
/**/
.v-custom-player .video-js .vjs-big-play-button .vjs-icon-placeholder:before {display: flex;align-items: center;justify-content: center;}
.v-custom-player .section-heading{font-size:24px;line-height:28px;color:#000;margin:0 0 40px;font-weight:bold;}
.v-custom-sitecards .exploreImg{width:136px;height:136px;margin:0 30px 20px 0;}
.v-custom-sitecards .exploreImg a{width:100%;height:100%;}
.v-custom-sitecards .exploreImg img{width:100%;height:100%;}
.v-custom-sitecards .no-video-found{height:150px;border:1px dashed #FD6F21;border-radius:10px;color:#FD6F21;font-size:18px;line-height:22px;}
.video-product-modal .modal-title{color:#FD6F21;font-weight:700;}
.video-product-modal .no-detail-found{height:100px;border:2px dashed #FD6F21;border-radius: 10px;color:#FD6F21;}
.v-player-container .video-site-content .v-description{padding:30px 33px;background:#fff;border-radius:10px;box-shadow: 0px 0px 14px #0000001A; margin-bottom: 50px;}
.clicked-object-detail{width:250px;background:#fff;border-radius:10px;padding:20px;box-shadow:0 0 10px rgba(0,0,0,0.3);position: fixed;top:20px;right:20px;font-size:16px;line-height:20px;}
.clicked-object-detail{display: none;}
.clicked-object-detail .object-name{font-size:22px;line-height:26px;margin:0 0 10px;color:#FD6F21;font-weight:700;}
.clicked-object-detail h3{font-size:16px;line-height:20px;color:#000;font-weight:600;margin:0;}
.clicked-object-detail .rating{font-size:10px;line-height:10px;color:#e1e1e1;}
.clicked-object-detail .rating .rated{color:#FD6F21}
.clicked-object-detail .link-to{font-size:12px;line-height:15px;color:#FD6F21;transition:all ease-in-out 0.3s;-o-transform: translateX(0);-ms-transform: translateX(0);-moz-transform: translateX(0);-webkit-transform: translateX(0);transform: translateX(0);}
.clicked-object-detail .link-to:hover{-o-transform: translateX(5px);-ms-transform: translateX(5px);-moz-transform: translateX(5px);-webkit-transform: translateX(5px);transform: translateX(5px);}
.like-dislike-btn {background-color: rgba(253, 110, 33, 0.1); border-radius: 30px; margin-right: 20px; overflow: hidden;}
.like-dislike-btn button,.video-site-content .share-button, .make-video-favorite button{font-size: 14px; line-height: 17px;font-weight: 500; border-color: transparent; background-color: transparent; padding: 8px 15px; color: rgba(253, 110, 33, 1); overflow: hidden; border-width: 0px;}
.like-dislike-btn button{border-radius: 0;min-width: 58px;}
.like-dislike-btn button:focus-visible ,.video-site-content .share-button:focus-visible{outline: none;}
.like-dislike-btn button:hover,.video-site-content .share-button:hover, .make-video-favorite button:hover{background-color: rgba(253, 110, 33, 0.2);}
/* .like-dislike-btn button:last-child:hover{ border-radius: 0 30px 30px 0;} */
.like-dislike-btn button:first-child{ position: relative;}
.like-dislike-btn button:first-child::before{content: ""; height: 100%; width: 1px; background-color: rgba(253, 110, 33, 0.4);; position: absolute; right: 0px;  top: 0;}
.video-site-content .share-button{background-color: rgba(253, 110, 33, 0.1); border-radius: 30px; margin-right: 20px;}
.make-video-favorite button{background-color: rgba(253, 110, 33, 0.1);border-radius: 30px; color: #FD6F21;}
.v-user-details .make-video-favorite .count{font-size: 14px; line-height: 17px; font-weight: 500;}
/* .like-dislike-btn button:last-child{padding: 0 15px 0 5px;} */
/*  */
.related-blogs{padding: 30px 33px; background: #fff; border-radius: 10px; box-shadow: 0px 0px 14px #0000001A;}
.related-blogs h4{font-size: 16px; line-height: 20px; font-weight: 500; color: #FD6F21; position: relative; margin-bottom: 27px;}
.related-blogs h4::before{content: ""; width: 30px; height: 2px; background-color: #FD6F21; position: absolute; bottom: 0; left: 0;}
.related-blogs .no-coments-yet{font-size: 14px; line-height: 17px; font-weight: 400; color: #FD6F21;}
.related-blogs .main-comment-wrapper{margin-bottom: 30px;}
.related-blogs .userImg{overflow: hidden; box-shadow:  0 0 10px rgba(253, 111, 33, 0.8);}
.related-blogs .text-area-wrapper{width: 100%; flex: 1; margin-left: 20px; position: relative;}
/* .text-area-wrapper .submit-btn{position: absolute; top: 0; right: 0;}  */
.text-area-wrapper textarea{background-color: transparent; border: none; border-bottom: 1px solid #C4C1C1; resize: none; border-radius: 0; max-height: 40px; padding-left: 0;}
.text-area-wrapper textarea:focus{border: none; border-bottom: 1px solid #FD6F21; background-color: transparent; }
.related-blogs .user-comment-wrapper{flex: 1; margin-left: 20px;}
.comment-user-name{font-size: 14px; line-height: 17px; font-weight: 500; color: #101010;}
.comment-time{font-size: 14px; line-height: 17px; color: #A2A2A2; font-weight: 300; margin-left: 27px;}
.related-blogs .comment-box-wrapper{margin-bottom: 45px;}
.comment{font-size: 14px; line-height: 18px; font-weight: 300; color: #101010; word-break: break-word;}
.related-blogs .show-btns {padding: 0; background-color: transparent; outline: none; color: #FD6F21; border: none; font-size: 13px; line-height: 17px;}
/*responsiveness*/
@media (max-width: 1899.98px){
    .v-player-container .video-detail-section .video-content, .v-player-container .video-detail-section .video-area {min-width: auto;}
    .v-custom-player {height: 500px;}
    .right.dashboard-sitePages .v-custom-player{height: 565px;}
    .sitePages-width .v-custom-player{height: 565px;}
}
@media (max-width: 1799.98px) {
    .v-player-container {height: calc(100vh - 56px);}
    .v-custom-player {height: 450px;}
    .right.dashboard-sitePages .v-custom-player{height: 510px;}
    .sitePages-width .v-custom-player{height: 510px;}
}
@media (max-width: 1599.98px){
    .video-site-content h2 {font-size: 33px;font-weight: 500;}
    .v-description span {font-size: 14px;line-height: 20px;}
    .v-custom-player {height: 378px;}
    .right.dashboard-sitePages .v-custom-player{height: 440px;}
    .sitePages-width .v-custom-player{height: 440px;}
    .v-custom-sitecards .exploreImg{width:100px;height:100px;margin:0 20px 0 0;}
    /* .exploreCard .cardFooter{width:calc(100% - 120px)} */
}
@media (max-width: 1399.98px){
    .video-site-content h2{font-size: 30px;line-height: 35px;}
    /**/
    .v-player-container .video-detail-section .video-content {width: 46%;}
    .v-player-container .video-detail-section .video-area {width: 50%;}
    .v-description span {font-size: 12px;line-height: 16px;}
    .v-custom-player {height: 250px;}
    .v-player-container {height: calc(100vh - 43px);}
    .right.dashboard-sitePages .v-custom-player{height: 300px;}
    .sitePages-width .v-custom-player{height: 300px;}
    .v-custom-sitecards .exploreImg img{height:100%}
}
@media (max-width: 1199.98px) {
    .v-custom-player {height: 200px;}
    .right.dashboard-sitePages .v-custom-player{height: 240px;}
    .sitePages-width .v-custom-player{height: 240px;}
    .v-player-container .v-description {margin: 0 0 50px;}
    /**/
    .v-user-details {padding: 10px 0;margin-bottom: 20px;}
    .v-player-container .v-user-details .img {width: 50px;height: 50px;}
    .video-site-content .user-name {font-size: 16px;line-height: 20px;}
    .video-site-content .publish-date {font-size: 12px;line-height: 16px;}
    .like-dislike-btn button,.video-site-content .share-button, .make-video-favorite button,.video-site-content .make-video-favorite .count{font-size: 13px; line-height: 16px;}
    .like-dislike-btn ,.video-site-content .share-button{margin-right: 10px;}
    .v-custom-sitecards .exploreCard .make-video-favorite .count, .video-site-content .make-video-favorite .count{font-size: 13px; line-height: 16px;}
}
@media (max-width: 991.98px){
   .v-custom-player {height: 290px;}
    .right.dashboard-sitePages .v-custom-player{height: 350px;}
    /**/
    .v-player-container .video-detail-section {flex-direction: column-reverse;}
    .v-player-container .video-detail-section .video-content, .v-player-container .video-detail-section .video-area {width: 100%;}
    .v-player-container .video-detail-section .video-content {padding-bottom: 10px;max-height: initial;overflow-x: hidden;overflow-y: hidden;padding:0 20px 20px;;}
    .v-player-container .video-detail-section .video-area {max-height: 500px;}
    .v-player-container .video-detail-section,  .v-player-container .container-fluid {height: auto !important;}
    /**/
    .sitePages-main-width .v-custom-player{height: 415px;}
}
@media (max-width: 767.98px){
    .video-site-content h2 {font-size: 26px;line-height: 28px;}
    /**/
    .v-custom-player {height: 180px;}
    .right.dashboard-sitePages .v-custom-player{height: 240px;}
     /**/
     .sitePages-main-width .v-custom-player{height: 300px;}
}
@media (max-width: 575.98px){
    .video-site-content h2 {font-size: 22px;line-height: 25px;text-align: left;}
    .v-custom-player {height: 250px;}
    /**/
    .sitePages-main-width .v-custom-player{height: 250px;}
}
@media (max-width: 479.98px) {
   .v-custom-player {height: 165px;}
   /**/
   .sitePages-main-width .v-custom-player{height: 165px;}
}
@media(max-width:385px){
    .like-dislike-btn button, .video-site-content .share-button, .make-video-favorite button{padding: 6px 9px;}
    .like-dislike-btn, .video-site-content .share-button {margin-right: 2px;}
}