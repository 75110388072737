.payment-history {
  padding-bottom: 50px;
}
.active-plans-details {
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
}
.active-plans-details h2 {
  margin-bottom: 30px;
}
.individual-plans {
  background-color: #f2f3f9;
  border-radius: 20px;
  padding: 40px;
}
.individual-plans h2 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #fd6f21;
  line-height: 50px;
}
.individual-plans-ul li {
  padding-left: 15px;
  font-weight: 400;
  margin-bottom: 8px;
}
.individual-plans-ul li::marker {
  content: "-";
}
/* .billing-and-payment {
  padding-left: 75px;
} */
.billing-and-payment h2 {
  margin-bottom: 50px;
}
.billing-and-payment p {
  font-size: 20px;
  font-weight: 400;
  opacity: 0.6;
  padding-left: 20px;
}
.billing-and-payment .credit-card-payment-info {
  margin-bottom: 40px;
}
.billing-and-payment .credit-card-payment-info img,
.billing-and-payment .billing-details img{width:30px;}
.billing-and-payment .details {
  margin-bottom: 50px;
}
.billing-and-payment .billing-details p {
  width: 265px;
}
.billing-and-payment .view-billing-history {
  margin-bottom: 50px;
}
.billing-and-payment .view-billing-history a {
  color: #fd6f21;
}
.billing-and-payment .view-billing-history a:hover {
  text-decoration: underline;
}

@media (max-width: 1699px) {
  .active-plans-details,
  .individual-plans {
    padding: 30px;
  }
  .individual-plans h2 {
    font-size: 38px;
    line-height: 46px;
  }
  .individual-plans-ul li{
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 1399px) {
  .billing-and-payment {
    padding-left: 40px;
  }
  .billing-and-payment h2,
  .billing-and-payment .details,
  .billing-and-payment .view-billing-history {
    margin-bottom: 30px;
  }
}

@media (max-width: 1399px) {
  .individual-plans h2 {
    font-size: 32px;
    line-height: 38px;
  }
  .active-plans-details,
  .individual-plans {
    padding: 20px;
  }
  .billing-and-payment {
    padding-left: 30px;
  }
}

@media (max-width: 991px) {
  .active-plans-details .individual-plans {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .billing-and-payment {
    padding-left: 20px;
  }
}

@media (max-width: 400px) {
  .payment-history .content.px40 {
    padding: 0 10px;
  }
  .active-plans-details,
  .individual-plans {
    padding: 10px;
  }
  .individual-plans h2 {
    font-size: 22px;
    line-height: 28px;
  }
  .billing-and-payment {
    padding-left: 0;
  }
}
