/* modal width */
.modal-dialog{padding: 10px;}
.settingModel .modal-dialog{max-width: 1160px; border-radius: 20px;}
.medium-Model .modal-dialog{max-width: 1160px;}
.uploadvideo .modal-dialog{max-width: 900px;}
.settingModel.confirmModel .modal-dialog{max-width: 600px;}
.narrowModel .modal-dialog{max-width: 710px;}
.fixedModal .modal-content{position: relative; overflow: hidden;}
.modelTabs .nav-pills{position: absolute;}
.videoModal{max-height: 800px; overflow-y: auto; overflow-x: hidden;padding-right: 5px;}
/*  */
.settingModel .modelTabs::-webkit-scrollbar { width: 8px; height: 8px; border-radius: 3px; margin-bottom: 10px;}
.settingModel .modelTabs::-webkit-scrollbar-track , .settingModel .modelTabs::-webkit-scrollbar-thumb{ background-color: #FD6F21; margin-bottom: 10px; border-radius: 3px;}
.modal .theme-btn, .modal button{padding: 16px 40px;}
.modal-content{background-color: #fff; padding: 30px; border-radius: 30px;}
.modalBox{padding: 30px 0px;}
.modal-body{padding: 145px 0px;}
.modal-header{padding: 0px 0px 30px 0px; border-bottom: 2px solid #6468662e;}
.modal-footer{padding: 21px 20px 20px 0px; border-top: 1px solid #6468662e;}
.modal-footer button{min-width:126px;height:50px;}
.vision-modal .modal-footer button, .modal-footer button{height: auto;}
.modal-title, .modal-H4{font-family: 'outfit'; font-size: 24px; line-height: 30px; font-weight:500; color: #505050;opacity: 1; margin-bottom: 16px;word-break: break-all;}
.modal p{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight:400;}
.modal .modalLightP{font-family: 'SofiaPro'; font-size: 20px; line-height: 26px; font-weight:300;}
.btn-close{opacity:1; filter: brightness(0) invert(0);}
/* .modal button{margin: top 40px;} */
.uploadBtn{display: flex; justify-content: center;  align-items: center; width:150px; height:150px; border-radius: 50%; background-color: #414357; margin: auto auto 40px auto;}
.uploadBtn svg{font-size: 50px;color:#fff;}
.check-H4{font-weight: 400 !important; opacity: 0.99 !important;}
.fileGroup{margin-bottom: 30px;}
.fileGroup > div{margin-bottom: 12px;}
.fileGroup .fileLabel{margin-bottom: 10px;}
.fileGroup .fileBlock, .fileGroupList textarea{background: #F2F3F8;border:1px solid #DBDBDB; border-radius: 10px; padding: 17px 20px; height: 84px; }
textarea:focus-visible{outline: none;}
.fileGroup .fileBlock p{opacity: 0.75;}
.fileGroupList textarea{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight:400; color: #212529 ; overflow-y: auto; border: 0px; opacity: 1;border:1px solid #E1E1E1;background:none;height:60px;resize:none;}
.fileGroupList textarea::-webkit-input-placeholder {color:#7f7f7f;}
.fileGroupList textarea:-moz-placeholder {color:#7f7f7f;opacity:1;}
.fileGroupList textarea::-moz-placeholder {color:#7f7f7f;opacity:1;}
.fileGroupList textarea:-ms-input-placeholder {color:#7f7f7f;}
.fileGroupList textarea::-ms-input-placeholder {color:#7f7f7f;}
.fileGroupList textarea::placeholder {color:#7f7f7f;}
input::-webkit-input-placeholder {color:#212529;}
input:-moz-placeholder {color:#212529;opacity:1;}
input::-moz-placeholder {color:#212529;opacity:1;}
input:-ms-input-placeholder {color:#212529;}
input::-ms-input-placeholder {color:#212529;}
input::placeholder {color:#212529;}
.fileGroupList textarea:focus{border-color: #FD6F2180;}
textarea:-moz-placeholder, /* Firefox 18- */
textarea::-moz-placeholder, /* Firefox 19+ */
textarea:-ms-input-placeholder, /* IE 10+ */
textarea::-webkit-input-placeholder, /* Webkit based */
textarea::placeholder { /* Modern browsers */ color: #0bf; opacity: 0.75;  }
.thumbnail p{opacity: 0.4;}
.thumbnailImg {display: flex;flex-wrap: wrap;}
.thumbnailImg > div {border:1px solid #E1E1E1;}
.thumbnailImg .videoImg:not(:last-child) {margin-right: 12px;}
.thumbnailImg .videoImg{width: 143px; height: 100px;border-radius: 10px; margin-bottom: 10px; border: 2px solid transparent; transition: all ease-in-out .3s;}
.thumbnailImg .videoImg:hover{border: 2px solid #FD6F21;}
.thumbnailImg .videoImg img {object-fit: cover;height: 100%; width: 100%;border-radius: 10px;}
/* MODAL TBS */
.settingModel .modelTabs{ height: calc(100% - 214px);  overflow-y: auto; overflow-x: hidden;}
.modelTabs .nav-pills{background: #fff; min-width: 250px; border-bottom-left-radius: 20px;}
.nav-pills .nav-link{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight:500; color: #FFFFFF;  padding: 25px 0px 25px 40px;border-radius: 0px; color: #505050;opacity: 1;}
.modelTabs .nav-pills .nav-link.active, .modelTabs .nav-pills .nav-link:hover{background:#F2F3F8; border-radius: 0px;color:#505050;}
/**/
.settingModel .modal-content{padding:0px ;}
.settingModel .modal-header{padding: 30px;position: relative;}
.settingModel .modal-content .btn-close {position: absolute;right: 40px;top: 50%;font-size: 20px;}
.settingModel .modal-body{padding: 40px 40px 10px 40px}
.settingModel .tab-content{padding: 40px 40px 20px 40px; border-radius: 20px; }
.tab-content>.active, .modelTabs .nav-pills, .settingModel .modelTabs > .row{height: 100% ;}
.settingModel h4{font-family: 'outfit'; font-size: 20px; line-height: 25px; font-weight:500; color: #505050;opacity: 1; margin-bottom: 16px;}
.modalSelect{max-width: 425px; height: 80px; border-radius: 10px; display: flex; align-items: center; position: relative;}
.modalSelect select{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight: 400; color: #505050; background-color: #fff; width: 100%; height: 100%; border:1px solid #E1E1E1; position: absolute; left: 0; right: 0; top:0; bottom:0; background-image: none; cursor: pointer;}
.modalSelect select ~ span svg{color:#505050;}
.modalSelect span{ margin-right: 30px; margin-left: auto;}
.settingFlex{ display: flex; flex-direction: column; justify-content: space-between; height: 100%;}
/**/
.settingModel .blueLink {color: #FD6F21;margin: 0 0 20px;}
.settingModel .blueLink:last-of-type {margin: 0;}
.settingModel .modalTable table {--bs-table-bg: transparent;}
.settingModel .modal-content {height: 80vh;}
.settingModel .modal-content .fixedRel {height: 100%;}
/**/
.settingModel.subtitle-modal .modal-content {height: auto;}
/**/
.vision-modal .modal-header{position: relative;}
.vision-modal .modal-content .btn-close {position: absolute;right: 15px;top: 37%;font-size: 20px;transform: translateY(-50%);}
/**/
.themeModal input[type="file"] {box-sizing: border-box;display: inline-block;font-size: 14px;padding: 8px;position: relative;overflow: hidden;width: 100%;appearance: none;-webkit-appearance: none;-webkit-box-sizing: border-box;z-index: 2;}

.themeModal input[type="file"]::-webkit-file-upload-button {background: transparent !important;color: #505050;font: inherit;padding: 0;position: relative;border: none;text-align: left;position: relative;z-index: 100;-webkit-appearance: none;top: 50%;position: relative;transform: translateY(-50%);left: 20px;}
.themeModal .fileGroupList{position: relative;}
.themeModal .fileGroupList input[type="file"]{font-size:0;letter-spacing: 0;background:transparent}
.themeModal .fileGroupList .choose-file-text{width:100%;height:100%;display:flex;justify-content:center;align-items:center;color:#505050;position: absolute;left:0;top:0;font-size:14px;line-height:14px;z-index:1;}
/* TAB */
.innerTabs  .react-tabs__tab-list{position: relative; border-bottom: 0px solid #6468662e; margin-bottom: 0px !important;}
.innerTabs  .react-tabs__tab-list::after{content:''; border-bottom: 1px solid #6468662e; width: 100%; position: absolute; border-bottom: 2px solid #6468662e; width: 107%; position: absolute; bottom: -2px; right: 0; left: -40px; margin: auto;}
.innerTabs .react-tabs__tab-list li:not(:last-child){ margin-right: 67px;}
.innerTabs .react-tabs__tab-panel{ padding:40px 0px 0px 0px;}
/* .innerTabs .react-tabs__tab-panel{padding-bottom: 0px !important;} */
.innerTabs .react-tabs__tab{ position: relative; font-family: 'Outfit'; font-size: 16px;line-height: 20px; font-weight: 500; border: 0px; padding-bottom: 10px; background: transparent; color: #505050; opacity: 1; transition: all ease-in-out .4s;opacity: 0.6; }
.innerTabs .react-tabs__tab:hover,
.innerTabs .react-tabs__tab.react-tabs__tab--selected{ color: #FD6F21; opacity: 1;}
.innerTabs .react-tabs__tab:hover:before , .innerTabs .react-tabs__tab.react-tabs__tab--selected::before{ transform: scaleX(1);}
.innerTabs .react-tabs__tab::before { content: ''; display: block; left: 0; bottom: 0; width: 100%; height: 4px; border-radius: 30px; position: absolute; background: #FD6F21; transform: scaleX(0); transition: all ease-in-out .4s;}
.innerTabs .react-tabs__tab::after{display: none;}
.innerTabs .react-tabs, .innerTabs .react-tabs__tab-panel{height: 100%;}
.innerTabs .general:not(:last-child) {margin-bottom:30px;}
.innerTab-input-group{background: transparent; border-radius: 10px; height: 80px;}
.innerTab-input-group input{font-size: 16px;line-height: 20px; font-weight: 400; height: 100%; width:100%; background: #F2F3F8 !important; opacity: 0.75; color: #000000; border-color: #DBDBDB;}
.innerTab-input-group input.form-control:focus{opacity: 0.75; color: #000000; border-color: #FD6F2180; outline: none;}
.advanceSetting .general{margin-bottom: 24px;}
.laws > div{margin-bottom: 32px;}
.laws h5 {font-size: 18px;line-height: 23px; font-weight: 400;}
.laws ul{padding-left: 0px; margin-bottom: 30px; list-style: none;} 
.laws ul li{display: flex; align-items: center;}
.laws ul li:not(:last-child){margin-bottom: 15px;}
/* .laws ul li::before{content:''; width: 21px; height: 21px; border-radius: 50%; border:2px solid#83848B; background-color: transparent; margin-right: 15px;} */
.laws ul li p{margin-bottom: 0px;}
.accountBtn{font-size: 18px;line-height: 20px; font-weight: 700; color: #FD6F21;}
.accountBtn:hover{ color: #FD6F21;}
.accountBtn.link::before{background: #FD6F21;}
.checkTable.modalCheck input, .checkTable.modalCheck .form-check-input[type=checkbox]{border-radius: 4px;}
.opacity-40 { opacity: 0.4 ;}
.max-95{max-width: 95%;}
/* Modal Table */ 
.modalTable{ background: transparent; border-radius: 0px; overflow-x: auto;}
.modalTable table{color: #FFFFFF;}
.modalTable table thead th p{ text-transform: capitalize; opacity: 0.8; }
.userName{ font-family: 'Outfit'; font-size: 18px; line-height: 23px; font-weight: 500; margin-bottom: 7px; opacity: 1;}
.roleProfile{display: flex; align-items: center;}
.roleProfile .roleImg{ height: 50px; width: 50px; border-radius: 50%; margin-right: 20px;}
.roleProfile .roleImg img{object-fit: cover; width: 100%; height:100%;}
.modalTable table td p.role{ text-transform: uppercase;}
.modalTable table th, .modalTable table td{ padding: 30px 15px 0px 0px; border-width: 0px; min-width: 145px;vertical-align: middle;}
.modalTable table tr:not(:last-child), .modalTable table thead { border-bottom: 1px solid #6468662e;}
.sideTab{width: 24%; z-index: 9;}
.faqTabs .accordion-header, .faqTabs .accordion-item, .faqTabs .accordion-button{background-color: #393C48; border-radius: 10px 10px 10px 10px !important; margin-bottom: 20px; box-shadow: none; border: 0px; align-items: flex-start; opacity: 1; color: #fff;}
.faqTabs .accordion-header, .faqTabs .accordion-button{margin-bottom: 0px !important;}
.faqTabs .accordion-button{padding: 24px 20px;}
.faqTabs .accordion-button p {margin-bottom:0px;}
.faqTabs .accordion-button::after{display: none;}
.accordionArrow{opacity: 0.4;}
.videoLinkBox{border-radius: 10px;background-color: #fff;border:1px solid #E1E1E1;max-width: 400px;}
.videoLinkImg figure{ height: 230px;}
.videoLinkImg figure img{width: 100%; height:100%; object-fit: cover;border-radius: 10px;}
.vidoDetails{padding: 20px 30px 30px 30px;}
.vidoDetails h5{ font-size: 20px; line-height: 25px; font-weight: 400; margin-bottom: 20px; opacity: 1;}
.vidoDetails p{ font-size: 14px; line-height: 18px; opacity: 0.4;}
.vidoDetails a{ font-size: 16px; line-height: 20px; color: #FD6F21;}
.vidoDetails a:hover{text-decoration: underline;}
.vidoDetails .text-copied{position: absolute;left:50%;top:-30px;padding:5px 7px;border-radius:4px;background:#FD6F21;color:#fff;font-size:12px;line-height:12px;margin:0;-o-transform: translateX(-50%);-ms-transform: translateX(-50%);-moz-transform: translateX(-50%);-webkit-transform: translateX(-50%);transform: translateX(-50%);opacity:1;}
.clipboardDiv{display: flex; align-items: center;justify-content: space-between;}
.clipboardDiv a {margin-right: 10px;}
/* .clipboardDiv svg{opacity: 0.6; cursor: pointer;} */
.clipboardDiv button{border:1px solid #FD6F21;border-radius:4px;background:transparent;color:#FD6F21;}
.clipboardDiv button:hover{background:#FD6F21;color:#fff;}
/* Tab Round Button */
.tabTitle{font-size: 14px; line-height: 18px; font-weight: 500; color: #fff; margin-bottom: 5px;text-align: left;}
.tickCircle svg{color: #fff; font-size: 13px;}
.animatedTab .nav-tabs .nav-link.active .tabTitle{color: #FD6F21;}
.animatedTab .nav-tabs .nav-item{display: flex; align-items: center; }
.animatedTab .nav-tabs .nav-item:not(:last-child){ width: 100%;}
.animatedTab .nav-tabs .nav-link { background: transparent; padding: 0px; border: 0px; }
.animatedTab .nav-tabs .nav-link .tickCircle{width: 30px; height: 30px; background: transparent; border-radius: 50%; border: 2px solid #6468662e; color: transparent; padding: 0px; display: flex; align-items: center; justify-content: flex-start;}
.animatedTab .nav-tabs .nav-link.active .tickCircle{background: #FD6F21; border: 2px solid #fff; color: #FD6F21; padding: 0px;}
.animatedTab .nav-tabs .nav-link .tickCircle{width: 30px; height: 30px; background: transparent; border-radius: 50%; border: 2px solid #6468662e; color: transparent; padding: 0px; display: flex; align-items: center; justify-content: center;}
.animatedTab .nav-tabs .nav-item .nav-link.active .circleBef::after{border: 1px solid #FD6F21;content: ''; width: 100%; width: calc(100% - 30px);}
.animatedTab .nav-tabs .nav-item:last-child .nav-link.active .circleBef::after{border: 00px solid #FD6F21;content: ''; display: block; height: 1px; width: 0px; background-color: #FD6F21; width: calc(100% - 100%);}
.circleBef{display: flex; align-items: center;}
.animatedTab .nav-tabs .nav-item:not(:last-child) .circleBef::after{content:''; display:block; height: 1px; width: 100%; background-color: #6468662e; border: 1px solid #6468662e;width: calc(100% - 30px);}
.tabButton, .animatedTab .nav-tabs .nav-link{width: 100%;}
.animatedTab .nav-tabs{border-bottom: 0px; justify-content: center; padding-top: 0px;width: 80%; display: flex; margin: 30px auto auto auto; flex-wrap: nowrap;}
.addGroup .addGroupList{margin-bottom: 20px; display: flex; align-items: center; justify-content: space-between;}
.addGroupList{padding: 24px 20px; border-radius: 10px;border:1px solid #e1e1e1;}
.h5Title{font-size: 20px; line-height: 25px; font-weight: 400; opacity: 0.8; margin-bottom: 8px;}
.visibilityBox{padding: 26px 20px; border:1px solid #e1e1e1; border-radius: 10px; }
.visibilityBox{margin-bottom: 20px;}
.visibilityBox ul{padding-left: 0px; list-style: none;}
.visibilityBox ul li{display: flex; align-items: flex-start; justify-content: flex-start;}
.visibilityBox ul.outer-UL li:not(:last-child){margin-bottom: 20px;}
/* .visibilityBox ul li::before{content: ''; width: 21px; height: 21px;border: 2px solid #83848B; background-color: transparent; margin-right: 16px;border-radius: 50%;} */
.modal .faqP{font-size: 18px; line-height: 22px; opacity: 0.5;}
.faqList p {opacity: 1; margin-bottom: 10px;}
.check-UL{line-height: none; padding-left: 0px;}
.check-UL li {display: flex; align-items: flex-start;}
.check-UL h4{font-weight: 400;}
.checkIcon{color: #00FF00; margin-right: 13px;}
.faq-LeftBorder{border-left: 2px solid #6468662e; padding-left: 20px; height: 100%;}
.andLine{position: relative; opacity: 0.4;}
.andLine h4 { overflow: hidden; text-align: center}
.andLine h4:before, .andLine h4:after { background-color: #fff; content: ""; display: inline-block; height: 2px; position: relative; vertical-align: middle; width: 50%;}
.andLine h4:before { right: 0.5em; margin-left: -50%;}
.andLine h4:after { left: 0.5em; margin-right: -50%;}
.customInput{display: flex;}
.radioFlex{display: flex; align-items: flex-start; margin-right: 16px;margin-top: 6px;}
.visibilityBox ul li .customInput input[type='radio']{visibility: hidden;}
.visibilityBox ul li .customInput input[type='radio']:after , .themeRadio ul li .customInput input[type='radio']:after{ width: 21px; height: 21px; border-radius: 50%; top: -7px; left: -1px;position: relative; background-color: transparent; content: ''; display: inline-block; visibility: visible; border: 2px solid #FD6F21;}
.visibilityBox ul li .customInput input[type='radio']:checked:after, .themeRadio ul li .customInput input[type='radio']:checked:after { width: 19px; height: 19px; border-radius: 15px; top: -7px; left: -1px; position: relative; background-color: #FD6F21; content: ''; display: inline-block; visibility: visible; border: 2px solid #FD6F21;}
.modal-content .btn-close{position: absolute;right: 15px;top: 30px;}
.modal-content .modal-title{padding-right: 25px;}


@media (max-width:1699px) {
/* Modal width */
.uploadvideo .modal-dialog{max-width: 750px;}
.narrowModel .modal-dialog{max-width: 550px;}
.settingModel .modal-dialog{max-width: 992px;}
.medium-Model .modal-dialog{max-width: 800px;}
/* .settingModel .modelTabs, .videoModal{height: 350px !important;} */
.settingModel .modelTabs {height: calc(100% - 173px);}
/*  */
.modalBox{padding: 20px 0px;}
.fileGroup{margin-bottom: 20px;}
.settingModel .modelTabs::-webkit-scrollbar { width: 4px; height: 4px; }
.settingModel .modelTabs::-webkit-scrollbar-track , .settingModel .modelTabs::-webkit-scrollbar-thumb, .settingModel .modelTabs::-webkit-scrollbar { border-radius: 3px; }
.modal .theme-btn, .modal button{padding: 13px 20px;}
.modelTabs .nav-pills{min-width: 180px; border-bottom-left-radius: 15px;}
.nav-pills .nav-link{ font-size: 14px; padding: 15px 0px 15px 25px;}
.settingModel .modal-header{padding: 20px;}
.settingModel .modal-body{padding: 20px 20px 10px 20px}
.settingModel .tab-content{padding: 20px 20px 20px 20px; border-radius: 15px;}
.settingModel h4{font-size: 14px; line-height: 19px; margin-bottom: 10px;}
/* .settingModel .modelTabs { height: 550px;} */
.modal-footer{padding: 15px 10px 10px 10px;}
.modalSelect{max-width: 325px; height: 50px; border-radius: 8px; }
.modalSelect select{ font-size: 14px; line-height: 18px;}
.modalSelect select:focus{border-color: #FD6F2180 !important;}
.modalSelect span{ margin-right: 15px;}
.modal-content{padding: 20px 15px 20px 20px; border-radius: 20px;}
.modal-body{padding: 100px 0px;}
.modal-header{padding: 0px 0px 20px 0px; }
.modal-title, .modal-H4{ font-size: 21px; line-height:25px; margin-bottom: 10px;}
.modal p{font-size: 13px; line-height: 19px; }
.modal p.userName,
.modal p.role,
.modal p.roleEmail{color:#505050}
.modalLightP{font-size: 16px; line-height: 20px; }
/* .modal button{margin: top 30px;} */
.uploadBtn{ width:90px; height:90px; margin: auto auto 20px auto;}
.uploadBtn svg{font-size: 45px;}
.thumbnailImg .videoImg:not(:last-child) {margin-right: 8px;}
.thumbnailImg .videoImg{width: 100px; height: 60px;border-radius: 8px; border-width: 1px;}
.thumbnailImg .videoImg:hover{border: 1px solid #FD6F21;}
.thumbnailImg .videoImg img {border-radius: 8px;}
.fileGroup .fileBlock, .fileGroupList textarea{ padding: 15px 18px; height: 60px; }
.modal .modalLightP{font-size: 16px; line-height: 19px;}
/* TAB */
.innerTabs  .react-tabs__tab-list::after{ left: -20px;}
.innerTabs .react-tabs__tab-list li:not(:last-child){ margin-right: 35px;}
.innerTabs .react-tabs__tab-panel{ padding:20px 0px 0px 0px;}
.innerTabs .react-tabs__tab{ padding-bottom: 8px; }
.innerTabs .react-tabs__tab::before { height: 2px; border-radius: 20px;}
.innerTabs .general:not(:last-child){margin-bottom:20px;}
.innerTab-input-group{border-radius: 8px; height: 50px;}
.innerTab-input-group input{font-size: 14px;line-height: 18px;}
.innerTab-input-group input:focus{border-color: #FD6F2180 !important;}
.advanceSetting .general{margin-bottom: 24px;}
.laws > div{margin-bottom: 22px;}
.laws h5 {font-size: 14px;line-height: 20px; }
.laws ul{margin-bottom: 20px;} 
.laws ul li:not(:last-child){margin-bottom: 10px;}
.laws ul li::before{ width: 15px; height: 15px;border:1px solid#83848B; background-color: transparent; margin-right: 10px;}
.accountBtn{font-size: 14px;line-height: 18px; }
/* Modal Table */ 
.userName{font-size: 16px; line-height: 22px; margin-bottom: 4px;}
.roleProfile .roleImg{ height: 40px; width: 40px; margin-right: 10px;}
.modalTable table th, .modalTable table td{ padding: 25px 15px 0px 0px;}
.faqTabs .accordion-header, .faqTabs .accordion-item, .faqTabs .accordion-button{ border-radius: 8px 8px 8px 8px !important; margin-bottom: 15px; }
.faqTabs .accordion-button{padding: 20px 15px;}
.videoLinkBox{max-width: 250px; border-radius: 8px;}
.videoLinkImg figure{ height: 180px;}
.videoLinkImg figure img{border-radius: 8px;}
.vidoDetails{padding: 15px 25px 25px 25px;}
.vidoDetails h5{ font-size: 18px; line-height: 22px; margin-bottom: 18px;}
.vidoDetails p{ font-size: 12px; line-height: 16px; }
.vidoDetails a{ font-size: 14px; line-height: 18px; }
.clipboardDiv a {margin-right: 30px;}
.addGroup .addGroupList{margin-bottom: 15px;}
.addGroupList{ padding: 20px 15px; border-radius: 8px;}
.h5Title{font-size: 16px; line-height: 19px; margin-bottom: 5px;}
.visibilityBox{padding: 20px 15px; border-radius: 8px; }
.visibilityBox{margin-bottom: 15px;}
.radioFlex{margin-right: 13px;margin-top:2px;}
.visibilityBox ul.outer-UL li:not(:last-child){margin-bottom: 15px;}
.visibilityBox ul li::before{width: 13px; height: 13px;}
.visibilityBox ul.outer-UL{padding-left: 25px;}
.visibilityBox ul li .customInput input[type='radio']:after, .themeRadio ul li .customInput input[type='radio']:after { width: 14px; height: 14px; top: -8px; left: 0px; visibility: visible; border: 1px solid #83848B;}
.visibilityBox ul li .customInput input[type='radio']:checked:after, .themeRadio ul li .customInput input[type='radio']:checked:after { width: 13px; height: 13px; top: -8px; left: 0px; border: 1px solid #FD6F21;}
}
.v-custom-player{position: relative; overflow: hidden; width: 100%; text-align: center;}
@media (max-width: 991.98px){
    .v-custom-player {
        margin-bottom: 20px;
    }
}
@media (max-width:1520px) {
    .settingModel .modelTabs {height: calc(100% - 149px);}
}
@media (max-width:1399px) {
.settingModel .modal-dialog{max-width: 950px;}
.settingModel .modelTabs {height: calc(100% - 147px);}
}
@media (max-width:992px) {
    .modelTabs .nav-pills{min-width: 150px;}
}
@media (max-width:767px) {
.videoLinkBox{max-width: 100%;}
/**/
.modal .theme-btn, .modal button {padding: 11px 20px;}
}
@media (max-width:575px) {
.modelTabs .nav-pills{position: unset;border-radius: 0px;}
.sideTab{width: 100%;}
.medium-Model .modal-dialog{padding: 20px;}
.innerTabs .react-tabs__tab-list li{margin: 0px 10px 10px 10px;}
.animatedTab .nav-tabs .nav-item:not(:last-child):after{min-width: 120px;}
/* .settingModel .modelTabs{height: auto !important;} */
.settingFlex.innerTabFlow {height: 200px; padding-right: 10px; overflow-y: auto; overflow-x: hidden;}
.settingModel .modelTabs{overflow-y: hidden;}
}
 @media (max-width:479px) {
.animatedTab .nav-tabs .nav-item:not(:last-child):after{min-width: 80px;}
.settingFlex.innerTabFlow { height: 160px;}
/**/
.videoModal .edit-delete-icons {padding: 0 !important;}
}
@media (max-width:375px) {
 .animatedTab .nav-tabs .nav-item:not(:last-child):after{min-width: 40px;}
}


 
