.payment-success-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 0 12px;
  background:#F2F3F8;
}
.payment-success-modal {
  width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 30px;
  padding: 50px 12px;
  max-width: 100%;
}
.payment-success-modal h3 {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 500;
}
.payment-success-modal p {
  font-size: 18px;
  opacity: 0.6;
  width: 409px;
  margin: 0 auto 40px auto;
}
.payment-success-modal .go-to-dashboard-btn {
  margin-bottom: 20px;
}
.payment-success-modal button {
  margin: 0 auto;
  max-height: 72px;
}
.payment-success-modal .view-invoice-btn button {
  background-color: #FD6F21;
  border-color: transparent;
  color: #fff;
}
.payment-success-modal .view-invoice-btn button:hover{
  border-color: #FD6F21;
  border-style: solid;
  color: #FD6F21;
  background-color  : transparent;
  box-shadow: none;
  outline: none;
}
.payment-success-sec .animated-check {
  height: 10em;
  width: 10em;
}

.payment-success-sec .animated-check path {
  fill: none;
  stroke: #7ac142;
  stroke-width: 1;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  animation: draw 2s linear forwards infinite;
  stroke-linecap: round;
  stroke-linejoin: round;
  /* animation-delay: 10s; */
}

@keyframes draw {
  0% {
      stroke-dashoffset: 23; 
  }
  50% {
      stroke-dashoffset: 0; 
  }
  100% {
      stroke-dashoffset: 0;
  }
}

@media (max-width: 575px) {
  .payment-success-modal p {
    width: 100%;
    font-size: 16px;
  }
  .payment-success-modal h3 {
    font-size: 26px;
  }
}
