#community-post{padding: 55px 0px;}
.btn:focus-visible{outline: none;}
.community-posts-wrapper{background-color: #fff;  width: 700px;}
.community-posts-wrapper .community-posts{padding: 30px 20px;}
.community-posts-wrapper .community-user-details-wrapper{margin-bottom: 25px;}
.community-posts-wrapper .community-author-img{width: 50px; height: 50px; border-radius: 50%; overflow: hidden;}
.comunity-author-nameDetails{margin-left: 10px;}
.community-name-follow a{font-size: 18px; line-height: 22px; font-weight: 500; color: #505050; margin-bottom: 0;}
.community-name-follow button{padding: 0; font-size: 13px; line-height: 17px; font-weight: 300; color: #FD6F21; margin-left: 8px;}
.community-name-follow button:hover{color: #FD6F21;}
.community-posts-wrapper .experience span{font-size: 13px; line-height: 17px; font-weight: 300; color: #505050; padding-right: 10px; position: relative; margin-right: 10px;}
.community-posts-wrapper .experience span::before{content: ""; width: 5px; height: 5px; border-radius: 50%; background-color: #505050; position: absolute; right: 0; top: 50%; transform: translateY(-50%);}
.community-posts-wrapper .experience span:last-child{padding-right: 0; margin-right: 0; }
.community-posts-wrapper .experience span:last-child::before{content: ""; display: none;}
.post-heading-discription a{font-size: 32px; line-height: 36px; font-weight: 700; color: #505050; margin-bottom: 20px; transition: all 0.3s ease-in-out; display: inline-block;}
.post-heading-discription a:hover{text-decoration: underline;}
.post-heading-discription p{font-size: 14px; line-height: 18px; font-weight: 300; color: #505050;}
.community-posts-wrapper .post-img{width: 100%;}
.community-posts-wrapper .post-img img{width: 100%;}
.votes-btn-wrapper{margin-bottom: 25px;}
.votes-btn-wrapper button{padding: 8px 15px; margin-right: 10px; margin-top: 0;}
.votes-btn-wrapper button:last-child{margin-right: 0;}
.community-posts .comments-count{font-size: 14px; line-height: 17px; font-weight: 300; color: #505050; margin-bottom: 0; padding: 0 10px; border: none; padding: 0 ; background-color: transparent; display: block; transition: all 0.3s ease-in-out}
.community-posts .comments-count:hover{color: #FD6F21;}
@media(max-width:767px){
    .community-posts-wrapper{width: 100%;}
}
@media(max-width:575px){
    .community-posts-wrapper .community-posts { padding: 16px 16px;}
    .community-posts-wrapper .community-author-img { width: 40px; height: 40px;}
    .community-name-follow a { font-size: 15px; line-height: 18px;}
    .community-posts-wrapper .community-user-details-wrapper { margin-bottom: 8px;}
    .post-heading-discription a { font-size: 22px; line-height: 25px; margin-bottom: 10px; }
}