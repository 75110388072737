.explore {padding-bottom: 100px;overflow:hidden;}
.wrapFlexBar{display: flex; justify-content: space-between; align-items: center; margin-bottom: 60px;}
.wrapVideos{ display: grid; grid-auto-rows: auto; grid-gap: 10px; grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); grid-column-gap: 16px;margin: 0 0 25px;}
.wrapVideos .exploreCard{margin-bottom: 35px;transition: all .5s ease-in-out;}
.wrapVideos .exploreCard:hover .video-img{opacity: 0.5;}
.wrapVideos .exploreCard .player-icon svg{fill: #fff;}
.exploreImg img{border-radius: 20px; margin-bottom: 20px; width: 100%;height: 200px;object-fit: cover;}
.userImg{display: inline-block; width: 48px; height: 48px;border-radius: 50%;}
.userImg img{width: 100%; height:100%; object-fit: cover;border-radius: 50%;}
.v-player-container .userImg img {border-radius: 50%;}
.explore .v-custom-sitecards .exploreImg{width: 100%; height: 300px;}
.exploreCard .cardFooter{display: flex; align-items: flex-start; justify-content: space-between; padding-top: 15px;}
.exploreCard .cardFooter .videoReview{display: flex; flex-direction: column;flex: 1;}
.exploreCard .cardFooter p{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight: 400; margin-bottom: 5px;display: -webkit-box;max-width: 100%;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;}
.exploreCard .cardFooter span{font-family: 'outfit'; font-size: 14px; line-height: 18px; font-weight: 400;opacity: 0.4;}
.explore-sec .custom-form-select select { min-width: 160px; }
.explore-search-input {width: 250px !important;height: 40px !important;}
.explore.explore-sec .dashboardHeader {display: flex;align-items: center;}
/**/
.v-player-container .v-user-details .img{width: 62px;height: 62px;}
.v-player-container .v-user-details .img img{border-radius: 50%;width: 100%;height: 100%;}
/**/
.notification-modal .userImg img {border-radius: 50%;}
.notification-modal .rest-owner{flex: 1;}
.explore-sec .custom-form-select select option{text-transform: capitalize;}
.player-icon {display: none;font-size: 50px;}
.wrapVideos .exploreCard:hover .player-icon{display: flex;position: absolute;top: 0;width: 100%;height: calc(100% - 20px);align-items: center;justify-content: center;}
.explore.explore-sec .load-more-btn{margin: 50px auto;max-height: 72px;}
/**/
.select-category-dropdown select {height: 40px;}
.explore.explore-sec .video-slider-block{margin:0 0 45px;}
.explore.explore-sec h3{font-size:24px;line-height:28px;font-weight:700}
.explore.explore-sec .video-slider .slick-list{overflow:visible}
.explore.explore-sec .video-slider .slick-track{left:0px;}
.explore.explore-sec .slide-holder,.explore.explore-sec .video-holder{padding:0 18px;}
.explore.explore-sec .img-holder{width:100%; height:194px; border-radius:12px;overflow: hidden;box-shadow:0 10px 10px rgba(0,0,0,0.3);margin:0 0 25px;position: relative;}
.explore.explore-sec .img-holder img{width:100%;height:100%; object-fit: cover; object-position: center center;}
.explore.explore-sec .play-icon-holder{position: absolute;inset: 0;font-size:40px;background: rgba(0,0,0,0.2);color:#FD6F21;-o-transform: scale(0);-ms-transform: scale(0);-moz-transform: scale(0);-webkit-transform: scale(0);transform: scale(0);transform-origin: center center;transition:  all ease-in-out 0.3s;}
.explore.explore-sec .img-holder:hover .play-icon-holder{-o-transform:scale(1);-ms-transform:scale(1);-moz-transform:scale(1);-webkit-transform:scale(1);transform:scale(1);}
.explore.explore-sec .play-icon-holder svg{cursor: pointer;transition: all ease-in-out 0.3s;-o-transform:scale(1);-ms-transform:scale(1);-moz-transform:scale(1);-webkit-transform:scale(1);transform:scale(1);}
.explore.explore-sec .play-icon-holder svg:hover{-o-transform: scale(1.3);-ms-transform: scale(1.3);-moz-transform: scale(1.3);-webkit-transform: scale(1.3);transform: scale(1.3);}
.explore.explore-sec .author-pic{width:53px;height:53px;border-radius:50%;margin:0 15px 0 0;overflow:hidden;}
.explore.explore-sec .video-info{width:calc(100% - 68px);}
.explore.explore-sec .video-title{font-size:20px;line-height:24px;margin:0 0 10px;color:#000;height:24px;-webkit-line-clamp:1;display:-webkit-box;-webkit-box-orient:vertical;overflow: hidden;text-overflow: ellipsis;word-break: break-all;}
.explore.explore-sec .channel-name,
.explore.explore-sec .video-cat{font-size:16px;line-height:20px;color:#000;opacity:0.5;}
.explore.explore-sec .video-slider .slick-arrow{position: absolute;top:-40px;right:0;left:auto;-o-transform: translate(0);-ms-transform: translate(0);-moz-transform: translate(0);-webkit-transform: translate(0);transform: translate(0);font-size:20px;color:#000;}
.explore.explore-sec .video-slider .slick-arrow:hover{color:#FD6F21;}
.explore.explore-sec .video-slider .slick-arrow:before{display:none;}
.explore.explore-sec .video-slider .slick-arrow.slick-prev{right:30px;}
.explore.explore-sec .video-holder{background: none;margin:0 0 40px; width: 25%;}
.explore.explore-sec .other-videos .img-holder{height:320px; width: 100%;}
.explore.explore-sec .author-pic img,
.explore.explore-sec .other-videos .img-holder img{width:100%;height:100%;object-fit: cover; object-position: center center;}
.explore.explore-sec .other-videos .video-title{height:24px;-webkit-line-clamp:1;display:-webkit-box;-webkit-box-orient:vertical;overflow:hidden;text-overflow: ellipsis;}
.explore.explore-sec .video-meta-content{width:calc(100% - 68px);}
/*  */
.channel-vedios-wrapper .video-slider-block{display: flex; align-items: center; flex-wrap: wrap;}
.vlogs-wrapper{padding: 70px 20px 70px 20px; background: #F2F3F8;}
/* MEDIA QUERY */
@media (max-width: 1899px) {
    /* .explore.explore-sec .video-holder{width:25%;} */
    .exploreCard .cardFooter p{ font-size: 16px; line-height: 20px;margin-bottom: 3px;}
    .exploreCard .cardFooter span{ font-size: 12px; line-height: 15px;}
    .explore.explore-sec .other-videos .img-holder{height:336px;}
    .explore .v-custom-sitecards .exploreImg{height: 374px;}

}
@media(max-width: 1799px){
    .explore.explore-sec .img-holder { width: 100%; height: 167px;}
    .explore.explore-sec .other-videos .img-holder{height:311px;}
    .explore .v-custom-sitecards .exploreImg{height: 349px;}
}
@media (max-width: 1699px) {
    .explore-search-btn{height: 40px;}
    /**/
    .wrapVideos {margin: 0 0 15px;}
    .explore .v-custom-sitecards .exploreImg{height: 335px;}
    .explore.explore-sec .other-videos .img-holder{height:303px;}
}
/* @media(max-width:1605px){
    .explore.explore-sec .other-videos .img-holder{height:276px; width: 276px;}
} */

@media(max-width:1599px){
    /* .explore.explore-sec .img-holder {
        width: 210px;
        height: 145px;
    } */
    /* .explore.explore-sec .other-videos .img-holder{height:278px;} */
    .explore .v-custom-sitecards .exploreImg{height: 310px;}
    .explore.explore-sec .other-videos .img-holder{height:278px;}
}
@media(max-width:1499px){
    .explore.explore-sec .other-videos .img-holder{height:250px;}
    .explore .v-custom-sitecards .exploreImg{height: 385px;}
}
@media (max-width: 1399px) {
    .wrapFlexBar{margin-bottom: 40px;}
    .exploreImg img { border-radius: 10px; margin-bottom: 12px;}
    .wrapVideos { grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); grid-column-gap: 12px;}
    .exploreCard .cardFooter p {font-size: 15px; line-height: 18px;}
    .exploreCard .cardFooter span { font-size: 11px; line-height: 13px;}
    .userImg { width: 35px; height: 35px;}
    /* .wrapVideos .exploreCard{margin-bottom: 30px;} */
    /**/
    .explore {padding-bottom: 60px;}
    .explore.explore-sec .video-holder{width:25%;}
    /* .explore.explore-sec .img-holder { width: 240px; height: 167px;} */
    .explore .v-custom-sitecards .exploreImg{height: 268px;}
    .explore.explore-sec .slide-holder, .explore.explore-sec .video-holder { padding: 0 7px;}
    .explore.explore-sec .channel-name, .explore.explore-sec .video-cat { font-size: 12px; line-height: 15px;}
}
@media(max-width:1299px){
    .explore.explore-sec .other-videos .img-holder{height:225px;}
    .explore .v-custom-sitecards .exploreImg{height: 243px;}
}
@media(max-width:1199px){
    .explore.explore-sec .img-holder { width: auto; height: 175px;}
    .explore.explore-sec .other-videos .img-holder{height:213px;}
}
@media(max-width:1099px){
    .explore.explore-sec .author-pic { width: 40px; height: 40px; margin: 0 7px 0 0;}
    .explore.explore-sec .other-videos .img-holder{height:188px;}
    .explore .v-custom-sitecards .exploreImg{height: 261px;}
}
@media (max-width:991px) {
    .explore.explore-sec .video-holder{width:50%;}
    .explore.explore-sec .img-holder { width: auto; height: 230px;}
    .explore.explore-sec .other-videos .img-holder{height:329px;}
    .explore .v-custom-sitecards .exploreImg{height: 228px;}
}
@media (max-width: 767px) {
    .explore.explore-sec .dashboardHeader {display: block;}
    .explore.explore-sec .video-holder{width:100%;}
    .explore.explore-sec .other-videos .img-holder {height:auto;}
    .explore.explore-sec .img-holder { height: 385px;}
    .vlogs-wrapper{padding: 50px 20px 50px 20px;}
}
@media (max-width: 575px) {
    .explore-search-videos .explore-search-btn{margin: 10px 10px 0 0 !important}
    .explore-search-videos .explore-search-btn:nth-child(2){margin-right: 0 !important;}
    .explore.explore-sec .video-slider .slick-track{left:0;}
    .explore.explore-sec .img-holder { height: 270px;}
    .vlogs-wrapper{padding: 40px 20px 40px 20px;}
}
@media (max-width: 467px) {
.wrapVideos {grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); }
/* .wrapVideos .exploreCard{margin-bottom: 15px;} */
.explore.explore-sec .fileGroupList div{width: 100%;}
.explore-search-input{width: 100% !important;}
.vlogs-wrapper{padding: 20px 20px 40px 20px;}
}  
@media (max-width: 367px) {
    .wrapFlexBar{flex-wrap: wrap;}
    .explore-search-input { width: 100% !important}
    .explore.explore-sec .img-holder { width: auto; height: 177px;}
 }   
 @media (max-width: 267px) {
.exploreCard .cardFooter{flex-direction: column; align-items: flex-start;}
.exploreCard .cardFooter div {margin-bottom: 10px;}
}   