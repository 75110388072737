@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Black.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-ExtraBold.woff2') format('woff2'),
       url('./assets/fonts/Outfit-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Bold.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-SemiBold.woff2') format('woff2'),
       url('./assets/fonts/Outfit-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Medium.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Regular.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Light.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-ExtraLight.woff2') format('woff2'),
       url('./assets/fonts/Outfit-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'outfit';
  src: url('./assets/fonts/Outfit-Thin.woff2') format('woff2'),
       url('./assets/fonts/Outfit-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'SofiaPro';
  src: url('./assets/fonts/SofiaProBold.woff2') format('woff2'),
       url('./assets/fonts/SofiaProBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'SofiaPro';
  src: url('./assets/fonts/SofiaProLight.woff2') format('woff2'),
       url('./assets/fonts/SofiaProLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
body{
  font-family: 'outfit';
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  color: #030C16;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body.dashboard-page{background:#F2F3F8;}
/* Scroll Bar Styles */
* {
  scrollbar-width: thin;
  scrollbar-color: #FD6F21 #ccc;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #ffe4e6;
}
::-webkit-scrollbar-thumb {
  background-color: #FD6F21;
}
#root{overflow: hidden;}
.form-select{border: 1px solid transparent;}
.form-select:focus{
  box-shadow: none;
  outline: none;
  border: 1px solid #FD6F2180;
}
.contained-img{
  width:100%;height:100%;object-fit: contain;
}
.cursor-pointer{cursor: pointer;}
.btn-theme-outline,.btn-theme-outline:focus{padding:16px 53px;border: 2px solid #E37229;border-radius:6px;color:#E37229;font-size:20px;line-height:24px;font-weight:700;transition:all ease-in-out 0.3s}
.btn-theme-outline:hover{background:#E37229;color:#fff;}
/*table responsive*/
.table-responsive::-webkit-scrollbar {background-color: #151721; height: 8px; }
.table-responsive::-webkit-scrollbar-button { background-color: #151721; height: 8px;width: 10px;}
.table-responsive::-webkit-scrollbar-track  { background-color:#151721; }
.table-responsive::-webkit-scrollbar-track-piece  { background-color: #151721;}
.table-responsive::-webkit-scrollbar-thumb  {  background: #FD6F21; }
.table-responsive::-webkit-scrollbar-corner { background-color:#151721;  }
.table-responsive::-webkit-resizer {background-color:#151721; }
.table-responsive{scrollbar-color:  #FD6F21 #151721 ;scrollbar-width: thin;}
.table-responsive::-webkit-scrollbar-button:single-button {background-color: #151721;display: block;border-style: solid;height: 8px;width: 10px;}
.table-responsive::-webkit-scrollbar-button:horizontal:start{border-width: 4px 4px 4px 0px;border-color: transparent #FD6F21 transparent transparent;}
.table-responsive::-webkit-scrollbar-button:horizontal:end{border-width: 4px 0px 4px 4px;border-color: transparent transparent transparent #FD6F21;}
.table-responsive{scrollbar-color: #FD6F21 #151721; scrollbar-width: thin;}
/**/
.theme-form::-webkit-scrollbar {background-color: #151721 ; width: 10px;}
.theme-form::-webkit-scrollbar-button { background-color: #151721 ; color: #151721 ; }
.theme-form::-webkit-scrollbar-track  { background-color:#151721 ; }
.theme-form::-webkit-scrollbar-track-piece  { background-color: #151721 }
.theme-form::-webkit-scrollbar-thumb  {  background:  #FD6F21; }
.theme-form::-webkit-scrollbar-corner { background-color:#151721 ;  }
.theme-form::-webkit-resizer {background-color:#151721 ; }
.theme-form::-webkit-scrollbar-button:single-button {display: none;}
.theme-form{scrollbar-color: #FD6F21 #151721; scrollbar-width: thin;}
/**/
select option {
  background-color: rgba(250, 250, 250, 0.3);
  background: rgba(250, 250, 250, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  padding: 5px 0;
}

option:checked , select option:hover{
  color: #fff !important;
  background-color: #424550 !important;
}
.customAlert{
  background: #F2F3F8;
  color: #000;
  border-color: #F2F3F8;
}

.theme{
  color: #FD6F21;
}
.customBox-header{
  max-width: 1840px;
}
.customBox{
  max-width: 1876px;
}
section{
  overflow: hidden;
  padding: 120px 0px 0px 0px;
}
a{
  font-size: 20px;
  line-height: 31px;
  font-weight: 400;
  text-decoration: none;
}
.ellipse{
  display: block; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}
.outfit-p{
  font-family: 'outfit';
  font-size: 20px;
  line-height: 30px;
  opacity: 0.6;
}
.sofia-p{
  font-family: 'SofiaPro';
  font-size: 24px;
  line-height: 36px;
  opacity: 0.69;
}
.danger-asterik{
  margin-left: 5px;
}
.link{
  position: relative;
  font-family: 'outfit'; 
  font-size: 16px; 
  line-height: 20px; 
  font-weight: 700; 
  color: #fff;
  background: transparent;
  border: 0px;
  text-decoration: none;
  display: block;
  width: fit-content;
  text-decoration: none;
  border-radius: 0px;
  padding:0px;
  min-height: auto;
  cursor: pointer;
  transition: all ease-in-out .4s;
}
.link:hover{color: #fff;}
.link.orangeLink{
  font-family: 'outfit'; 
  font-size: 20px; 
  line-height: 25px; 
  font-weight: 700; 
  color: #FD6F21;
}
.link.orangeLinkBtn{
  color: #FD6F21;
  min-height: auto;
  min-width: auto;
}
.orangeLink.link::before, .link.orangeLinkBtn::before{
  background: #FD6F21;
}
.link.themeLink{
  color: #FD6F21;
}
.link.themeLinkBtn{
  color: #FD6F21;
}
.themeLink.link::before, .link.themeLinkBtn::before{
  background: #FD6F21;
}
/* .theme-form .outer-p.link:hover{
  color: #FD6F21;
} */
.op6{opacity: 0.6 !important;}
.customSwitch .form-check-input:checked {
  background-color: #1F212F;
  border-color: #1F212F;
}
.customSwitch .form-check-input {
  background-color: #fe6c30;
  border: 1px solid #fff;
  width: 85px;
  height: 40px;
}
.customSwitch .form-switch .form-check-input:focus{
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}
.blueLink{
  display: block; 
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
}
.link:hover:before {
  transform: scaleX(1);
}
.link::before {
  content: '';
  display: block;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  background: #fff;
  transform: scaleX(0);
  transition: all ease-in-out .4s;
}
.cuetomModelBtn{
  background: transparent;
  border: 0px;
  padding: 0px;
  margin: 0px;
  text-align: inherit;
}
.theme-btn, button{
  display: block;
  width: fit-content;
  font-family: 'outfit';
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-decoration: none;
  border-radius: 10px;
  padding: 22px 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all ease-in-out .4s;
}
.btn-close{
  min-height: auto;
  height: auto;
}
.dbLogoutBtn{
  min-height: 48px !important;
  min-width: 110px !important;
  padding:17px 48px;
}
.dbLogoutBtnMob{
  min-height: auto !important;
  min-width: auto !important;
}
.modal .theme-btn{
min-width: 138px;
}
.modal .orange-btn {
  min-width: 126px;
}

.orange-main-button{
  color: #fff;
  background: #FD6F21;
  border: 2px solid #FD6F21;
  margin-top: 80px;
  font-weight:700;
  /* min-width: 216px; */
}
.orange-main-button:hover, .orange-main-button:active, .orange-main-button:focus{
  color: #FD6F21;
  background: transparent;
  background-color: transparent !important;
  border-color: #FD6F21 !important;
}

.orange-btn{
  color: #FD6F21;
  background: transparent;
  border: 2px solid #FD6F21;
  font-weight:700;
  display:inline-block;
  vertical-align: top;
  /* min-width: 216px; */
  transition: all ease-in-out 0.3s;
}
a.orange-btn{padding:17px 40px 18px;border-radius:10px;}
.orange-btn:hover, .orange-btn:active, .orange-btn:focus{
  color: #fff;
  background: #FD6F21;
  background-color: #FD6F21 !important;
  border-color: #FD6F21 !important;
}

.outline-btn{
  color: #FD6F21;
  background: transparent;
  margin-top: 80px;
  border: 1px solid #FD6F2180;
}
.outline-btn:hover{
  color: #FD6F21;
  background: #F9B52B1A;
  border: 1px solid #FD6F2180;
}
.black-btn{
  color: #fd6f21;
  background: transparent;
  min-width: 160px;
  border: 2px solid #FD6F21;
}
.black-btn:hover{
  background: #fd6f21;
  color: #fff;
}
.grey-btn{
  color: #fff;
  background: #FD6F21;
  border: 2px solid #FD6F21;
}
.grey-btn:hover, .grey-btn:active, .grey-btn:focus {
  color: #FD6F21;
  background: #fff !important;
  border-color: #FD6F21 !important;
}
.videoAnalytics.themeAnalytics .black-btn{min-width: auto;border-color:#fd6f21;color:#FD6F21;background:transparent}
/* .black-btn:hover{
  color: #FD6F21;
  background: transparent;
  border-color: #FD6F21;
} */
.italicLink{
  font-family: 'outfit';
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  opacity: 0.4;
  cursor: pointer;
  transition: all ease-in-out .4s;
}
.italicLink:hover{
  color: #FD6F21;
  opacity: 1;
  text-decoration: underline;
}
.textBtn{
  font-family: 'outfit';
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-transform: uppercase;
  opacity: 0.5;
}

h1{
  font-size: 60px;
  line-height: 72px;
  font-weight: 600;
  margin-bottom: 30px;
}
h2{
  font-size: 60px;
  line-height: 76px;
  font-weight: 700;
  margin-bottom: 30px;
}
.H2{
  font-size: 42px;
  line-height: 55px;
  font-weight: 600;
  margin-bottom:0px;
}
.sofia-h2{
  font-family: 'SofiaPro';
  font-size: 60px;
  line-height: 94px;
  font-weight: 700;
  margin-bottom:30px;
}
.sectionTitle{
  margin: 0 auto;
  z-index: 9999;
  position: relative;
}
.circle{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FD6F21;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.circle::before{
  content: '';
  display: block;
  width: 100%;
  height: 50%;
  margin: 5px;
  border-radius: 50%;
  background: #FD6F21;
}
.iconDD-Btn, .iconDD-Btn:active{
  padding: 0px;
  margin: 0px;
  background-color: transparent !important;
  border: 0px;
  font-size: inherit;
  min-height: auto;
  transition: all ease-in-out .4s;
  color: #000;
}
.themeEffect{
  transition: all ease-in-out .4s;
  color: #000;
}
.iconDD-Btn:hover, .themeEffect:hover, .iconDD-Btn:active,.iconDD-Btn.active,.iconDD-Btn.btn:first-child:active, .iconDD:hover .iconBtn, .iconDD.show .iconBtn{
  color: #FD6F21;
  cursor: pointer;
}
.themeEffect.disabled-icon {
  color: #FD6F21 !important;
  opacity: 0.3;
}
.themeEffect.disabled-icon:hover {
  cursor: not-allowed;
}
.iconDD-Btn::after{
  display: none;
}
.iconDD .dropdown-menu{
  padding: 0px;
  background: #fff;
  border-radius: 10px 0px 10px 10px;
  width: 218px;
  transform: translate(-200px, 45px) !important;
}
.iconDD .dropdown-item{
  font-family: 'outfit';
  font-size: 14px;
  line-height: 35px;
  font-weight: 400;
  border-radius: 10px 0px 10px 10px;
  color: #505050;
  transition: all ease-in-out .4s;
}
.iconDD .dropdown-item span {
  font-size:24px;
}
.barDD.iconDD .dropdown-menu {
  transform: translate(0px, 34px) !important;
}
.iconDD .dropdown-item:hover, .iconDD .dropdown-item:active, .iconDD .dropdown-item:focus{
  background: transparent;
  border: 0px;
  outline: none;
  color: #FD6F21;
}

/* TABLE */
.themeTable{
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  overflow-x: auto;
}
.themeTable table{color: #FFFFFF;padding: 0px 10px;}
.themeTable table thead th p{
  font-family: 'Outfit';
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  opacity: 0.6;
  margin-bottom: 0px;
  white-space: nowrap;
}
.themeTable table td p{
  font-family: 'Outfit';
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 5px;
  opacity: 1;
}
.themeTable table th, .themeTable table td{
  padding: 30px;
  border-width: 0px;
  min-width: 100%;
  background-color: transparent;
}
.themeTable table .th-area, .themeTable table .td-area{
  min-width: 150px;
}
.themeTable table .video-area{
  min-width: 250px;
  border-right: 1px solid rgba(225, 225, 225, 0.1);
}
.videoPara{max-width: 600px;}
.themeTable table th.checkId, .themeTable table td.checkId{
  min-width: auto;
}
.themeTable table tr:not(:last-child), .themeTable table thead {
  border-bottom: 1px solid #6468662e;
}

/* TAB */
.tabFlex, .subTabBrdr{
  position: relative;
  display: flex; 
  justify-content:space-between; 
  align-items: flex-end;
  border-bottom: 0px solid #6468662e !important;
} 
.tabFlex::after, .subTabBrdr::after{
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  left:0;
  bottom: 0;
  border-bottom: 2px solid #6468662e;
}
.subTabPanel{
  padding-top: 0px !important;
}
.subTabPanel .themeTable{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.tabFlex > ul {
  padding: 0px 40px;
}
.tabFlex .realtimeBox{
  padding-bottom: 31px ;
}
 .react-tabs__tab-list{
  border-bottom: 0px solid #6468662e;
  margin-bottom: 0px !important;
}
.tabFlex h4{
  font-family: 'outfit';
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  opacity: 0.75;
}
/* .themeAnalytics .react-tabs__tab-list{padding: 0px 40px;} */
.wrapForm .nav-tabs{
  border-bottom: 0px;
  margin-bottom: 0px;
}
.tabFlex{padding: 0px 40px 0px 0px; margin-right: 40px;}
.themeAnalytics .react-tabs__tab-list li:not(:last-child){
  margin-right: 80px;
}
.themeAnalytics .react-tabs__tab-panel, .themeAnalytics .react-tabs__tab-panel--selected {padding:40px 0;}
.themeAnalytics .react-tabs__tab, .wrapForm .nav-tabs .nav-link{
  position: relative;
  font-family: 'Outfit';
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  border: 0px;
  padding-bottom: 18px;
  background: transparent;
  color:#505050;
  margin-right: 10px;
  transition: all ease-in-out .4s;
}
.themeAnalytics .tabFlex > ul li:not(:last-child){margin-right: 40px;}
.themeAnalytics .react-tabs__tab, .wrapForm .nav-tabs .nav-link:hover{color:#FD6F21;}
.themeAnalytics .react-tabs__tab.react-tabs__tab--selected, .wrapForm .nav-tabs .nav-link.active{
  color: #FD6F21;
  opacity: 1;
}
.themeAnalytics .react-tabs__tab:hover:before , .themeAnalytics .react-tabs__tab.react-tabs__tab--selected::before, .wrapForm .react-tabs__tab:hover:before, .wrapForm .nav-tabs .nav-link:hover:before, .wrapForm .nav-tabs .nav-link.active::before{
  transform: scaleX(1);
}
button:focus-visible, a:focus-visible{outline: none;}
.themeAnalytics .react-tabs__tab::before, .wrapForm .nav-tabs .nav-link::before {
  content: '';
  display: block;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 30px;
  position: absolute;
  background: #FD6F21;
  transform: scaleX(0);
  transition: all ease-in-out .4s;
}
.dasabcdhboardBody{background:#F2F3F8;overflow:hidden;}
.dashboard-sitePages p.md-P{
  font-family: 'Outfit';
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #FFFFFF;
  opacity: 0.4;
}
.themeAnalytics .react-tabs__tab::after, .wrapForm .nav-tabs .nav-link::after {
  display: none;
}
input[type=checkbox], input[type='radio'] {
  cursor: pointer;
  visibility: hidden;
}
input[type=checkbox]:focus, input[type=checkbox]:checked {
  box-shadow: 0 0 0 0.1rem transparent;
  color: #FD6F21;
}
.settingModel  .checkboxText h4{font-weight: 400;}
.checkTable .form-check-input[type=checkbox]{
  margin: 0px;
}
.form-check-input[type=date], .inputDiv input, input[type=date]{
  color: #212529;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.calendar-block{
  position: relative;
}
.theme-calendar{
  position: absolute;
  width: 100%;
}
/* ::-webkit-calendar-picker-indicator {
  opacity: 0;
  font-size: 50px;
  cursor: pointer;
} */
.inputDiv input.form-control:focus, .inputDiv input.form-control:focus-visible{
  background: transparent !important;
}
.inputDiv.calendar-block svg {
  margin-left: auto;
  cursor: pointer;
  color: #FD6F21;
}
.form-check.checkTable{
  margin-bottom: 0px;
  min-height: auto;
  padding-left: 0;
}
/* GRAPhH CSS */
.graphItems ul {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.graphItems ul  li p{
  font-size: 20px;
  line-height: 48px;
  font-weight: 400;
  opacity: 0.6;
  margin-bottom: 0px;
}
.graphItems ul  li:not(:last-child){
  margin-right: 80px;
}
.graphItems ul  li > div {
  display: flex;
  align-items: center;
}
.graphItems ul  li > div::before{
  content: '';
  display: block;
  height: 2px;
  width: 18px;
  border-radius: 2px;
  margin-right: 20px;
}
.graphItems ul  li > .yellow::before{
  background-color: #F9B52B;
}
.graphItems ul  li > .blue::before{
  background-color: #5490F2;
}
.graphItems ul  li > .orange::before{
  background-color: #FD6F21;
}
.custom-form-select select {
  background: #fff;
  border-color: #E1E1E1;
  color: #212529;
  appearance: auto;
  height: 60px;
  border-radius: 10px;
}
.custom-form-select .form-select:focus {border-color: #FD6F2180;}
.category-video-btn {min-width: unset !important;min-height: unset;font-size: 16px;padding: 7px 15px;}
.custom-react-select .triage-select__control {background-color: #fff !important; border-color: #505050 !important;color: #fff !important;min-width: 140px;height: 40px;font-size: 16px;font-weight: normal;}
.triage-select__control--menu-is-open, .triage-select__control--is-focused {box-shadow: 0 0 0 1px #FD6F2180 !important;}
.triage-select__indicator-separator{background:#505050 !important;}
.triage-select__indicator svg{color:#505050 !important;}
.custom-react-select .triage-select__menu {background-color: #fff !important;}
.custom-react-select .triage-select__menu .triage-select__menu-list {max-height: 115px;}
.custom-react-select .triage-select__single-value {color: #505050 !important;}
.custom-react-select .triage-select__option {font-size: 16px;font-weight: normal;background-color:#fff !important;cursor: pointer;}
.custom-react-select .triage-select__option:hover,
.custom-react-select .triage-select__option--is-selected ,
.custom-react-select .triage-select__option--is-focused {background-color: #FD6F21 !important;color:#fff !important;}
.Toastify__toast-container--top-right {z-index: 999999 !important;}
.btn-close:hover{transform: rotate(180deg);}
/* .theme-capcha div, .theme-capcha iframe {width: 100% !important;} */
.theme-capcha #rc-anchor-alert, .rc-anchor-alert{width: 300px !important;}
.table tr th, .table tr td {vertical-align: middle;color: #505050;}
.date-picker-wrapper input {border: 1px solid #FD6F21;background: transparent;color:#FD6F21;}
.date-picker-wrapper input::-webkit-input-placeholder {color:#FD6F21;}
.date-picker-wrapper input:-moz-placeholder {color:#FD6F21;opacity:1;}
.date-picker-wrapper input::-moz-placeholder {color:#FD6F21;opacity:1;}
.date-picker-wrapper input:-ms-input-placeholder {color:#FD6F21;}
.date-picker-wrapper input::-ms-input-placeholder {color:#FD6F21;}
.date-picker-wrapper input::placeholder {color:#FD6F21;}
.date-picker-wrapper input:focus, .date-picker-wrapper input:focus-visible{border: 1px solid #FD6F21;background: transparent;}
.date-picker-wrapper {position: relative;}
.date-picker-wrapper svg{position: absolute;right: 10px;top: 20px;color: #FD6F21;cursor: pointer;}
.date-picker-wrapper svg:hover{color: #FD6F21;}
.go-tp-profile img{border-radius: 50%;object-fit: cover; border: 2px solid #FD6F21;}
.go-tp-profile .userImg{width:36px;height: 36px;border-radius:50%;background: rgb(227,114,41);background: linear-gradient(180deg, rgba(227,114,41,1) 0%, rgba(80,80,80,1) 100%);border:none;padding:4px;}
.go-tp-profile .userImg img{border:none;width: 100%;height: auto;object-fit: cover;}
.go-tp-profile .userImg:hover{background: rgb(227,114,41);background: linear-gradient(0deg, rgba(227,114,41,1) 0%, rgba(80,80,80,1) 100%);}
.go-tp-profile .dropdown-menu-dark{background:#fff;padding:0;overflow: hidden;}
.go-tp-profile .dropdown-menu-dark a,
.go-tp-profile .dropdown-item p{color:#505050;}
.go-tp-profile .dropdown-menu-dark a:hover p,
.go-tp-profile .dropdown-item p:hover{color:#fff;}
.animatedTab .visibilityList .outer-UL {padding-left: 20px;}
.btn:disabled {background-color: #FD6F21;border-color: #FD6F21;}
.memberdashboard-icons {display: flex;justify-content: flex-end;margin: 8px 8px 0 0}
.memberdashboard-icons .navBtns {margin-right: 8px;}
.notification-modal .list-unstyled li:not(:last-child) {border-bottom: 1px solid #ffffff73;}
.notification-modal .list-unstyled li{padding: 10px 0;}
.notification-modal .notification-text, .notification-modal .notification-text a, .notification-modal .notification-date{font-size: 16px;line-height: 20px;}
.analytic-box .react-time-picker__inputGroup__input:focus-visible ,.modal-content .react-time-picker__inputGroup__input:focus-visible{outline: none;}
.analytic-box .react-time-picker__inputGroup__input:invalid, .modal-content .react-time-picker__inputGroup__input:invalid{background: transparent;}
.remove-img {
  position: absolute;
  top: 6px;
  right: 18px;
  background-color: #d5d0d0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.remove-img svg {
  font-size: 12px;
  line-height: 12px;
  color: #767272;
}
.remove-img:hover{background-color: #c3baba;}
.react-tooltip{font-size: 14px !important;line-height: 18px;background-color: #FD6F21 !important;padding: 4px 8px !important;max-width: 280px;text-align: center;}
/* .react-tooltip:focus{opacity: 0;} */
.color-red{color: red;}
.delete-all-icon{font-size: 24px !important;}
.go-tp-profile .dropdown-item span{color: #fd6f21;font-size: 16px;}
/* .logout-dropdown:active{background-color: rgba(225, 225, 225, 0.15) !important;} */
.my-videos-sec .table tr td{vertical-align: top;}
.my-channel-img {width: 150px;height: 150px;}
.my-channel-img img{width: 100%;height: 100%;object-fit: cover;border-radius: 50%;}
.choose-thumbnail-img{height: unset !important;}
.search-result-items{height: 400px;overflow: hidden;overflow-y: auto;}
/**/
.search-result-items .list-group-item {background: transparent linear-gradient(270deg, #505050 0%, #ee8d4c 100%) 0% 0% no-repeat; color: #fff;border-top: none;border-inline: none; padding: 16px;}
.search-result-items .list-group-item:last-of-type{border-bottom: none;}
.search-result-items .list-group-item a .list-group-item{border-radius: 5px;}
/**/
.search-results.non-active-user{right: 130px;}
.my-videos-sec .orange-btn{min-width: 180px;min-height: unset;}
.help-sec a{color: #fa6400;}
.help-sec a:hover{text-decoration: underline;}
.rc-pagination-item a{font-size: 18px;line-height: 26px;}
.faq-sec .faqs-content img{width: 60px;height: 60px;border-radius: 50%;object-fit: cover;margin-right: 10px;}
.faq-sec figure{margin-bottom: 8px;}
.faq-sec .table tr td{padding: 10px;}
.disabled-icon{opacity: 0.5;cursor: no-drop;}
.go-tp-profile .dropdown-item:hover,
.go-tp-profile .dropdown-item:focus{background-color: #FD6F21;color: #fff;}
.go-tp-profile .dropdown-item:focus{background-color: #FD6F21;color: #fff;}
.go-tp-profile .dropdown-item:hover span,
.go-tp-profile .dropdown-item:focus span{color: #fff;}
.go-tp-profile .dropdown-item:hover p, .go-tp-profile .dropdown-item:focus p{opacity: 1;}
.sidebar-video-wrapper .video-wrapper .image{width: 100%;height: 140px;border-radius: 10px;overflow: hidden;}
.sidebar-video-wrapper .video-wrapper .image img{width: 100%;height: 100%;object-fit: cover;}
.informative-sec .bar{height: 100% !important;z-index: -1;top: 0;}
.notification-counter {font-size: 13px;position: absolute;top: -10px;right:-5px;color:#FD6F21;font-weight: bold;}
.notification-btn-close{transition: all .5s ease-in-out;}
.notification-btn-close:hover{transform: rotate(180deg);}
.v-description .link.orangeLinkBtn{min-height: unset;min-width: unset;display: inline-block;margin-left: 7px;font-size: inherit;}
.informative-sec{min-height: calc(100vh - 512px);}
#shadow-host-companion{padding: 0;}
/**/
#rc-anchor-container {border-radius: 12px;background: #fff;color: #0D0F17;border-radius: 12px;}
/**/
.__floater.__floater__open {filter: drop-shadow(rgba(255, 255, 255, 1) 0px 0px 3px) !important;}
.__floater__arrow { filter: drop-shadow(rgba(0, 0, 0, 1) 0px -5px 2px) !important;}
.__floater__body .react-joyride__tooltip {background:#fff 0% 0% no-repeat padding-box !important;border-radius: 10px !important;color: #505050 !important;box-shadow:0 0 5px rgba(0,0,0,0.3);}
._floater__body ._floater__arrow polygon {fill: #FD6F21;}
.__floater__body .react-joyride__tooltip button {min-height: auto;}
.__floater__body .react-joyride__tooltip button:focus-visible {outline:none;box-shadow:none;}
.__floater__body .react-joyride__tooltip button svg path {fill: #000;}
.__floater__body .react-joyride__tooltip button[aria-label="Next"] {background-color: #FD6F21 !important;border-radius: 4px !important;padding: 10px 20px !important;border: 1px solid #FD6F21 !important;}
.__floater__body .react-joyride__tooltip button[aria-label="Next"]:hover {color: #FD6F21 !important;background-color: transparent !important;}
.__floater__body .react-joyride__tooltip button[aria-label="Skip"] {color: #FD6F21 !important;border-radius: 4px !important;padding: 10px 20px !important;border: 1px solid #FD6F21 !important;}
.__floater__body .react-joyride__tooltip button[aria-label="Skip"]:hover {color: #fff !important;background-color: #FD6F21 !important;}


.__floater__body .react-joyride__tooltip > div > button[aria-label="Close"] {background-color: #FD6F21 !important;border-radius: 4px !important;padding: 10px 20px !important;border: 1px solid #FD6F21 !important;}
.__floater__body .react-joyride__tooltip > div > button[aria-label="Close"]:hover {color: #FD6F21 !important;background-color: transparent !important;}

.__floater__body .react-joyride__tooltip button[aria-label="Back"] {color: #FD6F21 !important;border-radius: 4px !important;padding: 10px 20px !important;border: 1px solid #FD6F21 !important;}
.__floater__body .react-joyride__tooltip button[aria-label="Back"]:hover {color: #fff !important;background-color: #FD6F21 !important;}

/*video-modal*/
/* .video-modal .modal-content {height: calc(100vh - 100px);} */
.video-modal .modal-content .animatedTab {height: 100%;}
.video-modal .modal-content .animatedTab .videoModal {max-height: calc(100% - 169px);}
.video-modal .modal-header {position: relative;margin: 0 0 20px;}
.video-modal .modal-content .btn-close {position: absolute;right: 10px;top: 37%;transform: translateY(-50%);}
.video-modal .modal-header .modal-title {margin-bottom: 0;}
.video-modal .video-dragger-holder > div > label {width: 100px;}
.video-modal .video-dragger-holder > div > input {width: calc(100% - 140px);}
.video-modal .video-dragger-holder > div > .icon-holder {width: 20px;text-align: center;}
.video-modal .edit-delete-icons .icon-holder {width: 48px;height: 48px;border-radius: 8px;background: #fd6f21; border: 1px solid #fd6f21; color: #fff; display: flex;justify-content: center;align-items: center;margin-right: 10px; transition: all 0.3s ease-in-out;}
.video-modal .edit-delete-icons .icon-holder:hover{background: transparent; color: #fd6f21;}
.video-modal .edit-delete-icons .icon-holder:last-of-type {margin-right: 0;}
.video-modal .orange-btn {margin-top: 0px;max-height: 72px;border-radius: 10px;}
.video-modal .modal-footer .orange-btn{margin-top: 10px;}
.video-modal .updated-span {margin-left: 20px;background: rgb(0 128 0 / 40%);border: 1px solid green;padding: 6px 20px;border-radius: 5px;font-size: 18px;display: inline-block;}
/**/
.swal2-popup {background: #fff 0% 0% no-repeat padding-box;border-radius: 10px;color: #505050;}
/* .swal2-popup #swal2-html-container {opacity: 0.4;} */
.swal2-popup .swal2-actions button {color: #FD6F21 ;border: 1px solid #FD6F21 ;background-color: transparent !important;}
.swal2-popup .swal2-actions button:hover {color: #fff ;border: 1px solid #FD6F21 ;background-color: #FD6F21 !important;}
/**/
.react-datepicker-popper .react-datepicker{
  background-color: #fff;
}
.react-datepicker-popper .react-datepicker__header {
  background-color: transparent;
  border-bottom: 1px solid #fff;
} 
.react-datepicker-popper .react-datepicker__month{
  background-color: transparent;
}
.react-datepicker-popper .react-datepicker__current-month, 
.react-datepicker-popper .react-datepicker-time__header, 
.react-datepicker-popper .react-datepicker-year-header{
  color: #505050;
}
.react-datepicker-popper .react-datepicker__day-name, 
.react-datepicker-popper .react-datepicker__day, 
.react-datepicker-popper .react-datepicker__time-name{
  color: #fff;
}
.react-datepicker-popper .react-datepicker__day, 
.react-datepicker-popper .react-datepicker__month-text, 
.react-datepicker-popper .react-datepicker__quarter-text, 
.react-datepicker-popper .react-datepicker__year-text {
  color:#505050;
}
.react-datepicker-popper .react-datepicker__day:hover, 
.react-datepicker-popper .react-datepicker__month-text:hover, 
.react-datepicker-popper .react-datepicker__quarter-text:hover, 
.react-datepicker-popper .react-datepicker__year-text:hover,
.react-datepicker-popper .react-datepicker__month-text--keyboard-selected {
  background-color: #FD6F21;
  color:#fff;
}

.react-datepicker-popper .react-datepicker__day--selected, 
.react-datepicker-popper .react-datepicker__day--in-selecting-range, 
.react-datepicker-popper .react-datepicker__day--in-range, 
.react-datepicker-popper .react-datepicker__month-text--selected, 
.react-datepicker-popper .react-datepicker__month-text--in-selecting-range, 
.react-datepicker-popper .react-datepicker__month-text--in-range, 
.react-datepicker-popper .react-datepicker__quarter-text--selected, 
.react-datepicker-popper .react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker-popper .react-datepicker__quarter-text--in-range, 
.react-datepicker-popper .react-datepicker__year-text--selected, 
.react-datepicker-popper .react-datepicker__year-text--in-selecting-range, 
.react-datepicker-popper .react-datepicker__year-text--in-range{
  background-color: #FD6F21;
  color:#fff;
}
.react-datepicker-popper .react-datepicker__day--keyboard-selected, 
.react-datepicker-popper .react-datepicker__month-text--keyboard-selected, 
.react-datepicker-popper .react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker-popper .react-datepicker__year-text--keyboard-selected{
  background-color: #FD6F21;
}
.modal-backdrop{
  background-color:#0D0F17;
}
.modal-backdrop.show{opacity:0.7;}
.no-record-found{height:150px;border:1px dashed #FD6F21;border-radius:10px;color:#FD6F21;font-size:18px;line-height:22px;padding:15px;}
.no-record-found.no-category-found{height:40px;padding:10px;}
.swal2-styled:focus{outline:none !important;box-shadow: none !important;}
.error-span { position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); font-size: 14px;}
.for-rev-icons{color: #fd6f21;}
/*  */
.community-wrapper{background-color: #F2F3F8;}
/* MEDIA QUERIESSSSS */
@media (max-width:1722px){
.customBox{ max-width: 1600px; }
}
@media (max-width:1600px){
.customBox{ max-width: 1500px; }
}
@media (max-width:1500px){
  .customBox{ max-width: 1400px; }
  .go-tp-profile span{font-size: 18px;}
}
@media (max-width:1430px){
  .customBox{ max-width: 1300px; }
  .video-modal .updated-span {padding: 2px 13px;font-size: 14px;}
}
@media (max-width:1300px){
  .customBox{ max-width: 1200px; }
}
@media (max-width:1200px){
  .customBox{ max-width: 1100px; }
}
@media (max-width:1100px){
  .customBox{ max-width: 1000px; }
}
@media (max-width:1000px){
  .customBox{ max-width: 900px; }
}
@media (max-width:900px){
  .customBox{ max-width: 800px; }
}
@media (max-width:800px){
  .customBox{ max-width: 650px; }
}
@media (max-width:700px){
  .customBox{ max-width: 600px; }
}
@media (max-width:600px){
  .customBox{ max-width: 500px; }
  .animatedTab .visibilityList .outer-UL {padding-left: 10px;}
}
@media (max-width:1699px) {
.iconDD .dropdown-menu{ border-radius: 8px; width: auto; transform: translate(-150px, 34px) !important;}
.iconDD .dropdown-item{ font-size: 13px; line-height: 25px; border-radius: 8px 0px 8px 8px; padding: 8px 8px;}
.themeAnalytics .react-tabs__tab-list { padding: 0px 20px;}
/* TABLE */
.themeTable{ border-radius: 8px;}
.themeTable table thead th p, .themeTable table td p{ font-size: 16px; line-height: 20px;}
.themeTable table td .videoInfo span.description{font-size: 14px;line-height: 18px;}
/* .themeTable table th, .themeTable table td{ padding: 25px 25px 25px 25px;} */
.dashboard-sitePages p.md-P{font-size: 13px; line-height: 20px;}
.dashboard-sitePages .analyticDetailBox h4{margin-bottom: 3px;}
/* TAB */
.themeAnalytics .react-tabs__tab-list{padding: 0px 20px;}
.themeAnalytics .react-tabs__tab-list li:not(:last-child){margin-right: 50px;}
.themeAnalytics .react-tabs__tab-panel, .themeAnalytics .react-tabs__tab-panel--selected {padding:20px 0;}
.themeAnalytics .react-tabs__tab, .wrapForm .nav-tabs .nav-link{ font-size: 17px; line-height: 20px; padding-bottom: 18px;}
.themeAnalytics .react-tabs__tab::before, .wrapForm .nav-tabs .nav-link::before { height: 2px; border-radius: 20px;}
.link.orangeLink, .tabFlex h4{ font-size: 18px;  line-height: 22px; }
.tabFlex > ul { padding: 0px 20px;}
.tabFlex .realtimeBox { padding-bottom: 20px;}
.checkTable .form-check-input[type=checkbox]{font-size: 13px;}
.graphItems ul  li p{ font-size: 17px; line-height: 20px;}
.graphItems ul  li:not(:last-child){ margin-right: 40px;}
.graphItems ul  li > div::before{ height: 2px; width: 13px; border-radius: 1px; margin-right: 10px;}
.customSwitch .form-check-input { width: 50px; height: 25px;}
.search-results.non-active-user {right: 104px;}
.date-picker-wrapper svg{top: 12px;font-size: 16px;}
.notification-counter{top: 2px;right: 4px;font-size: 12px;text-align:right;line-height:12px;}
/**/
h1 {font-size: 53px;line-height: 66px;}
.theme-btn, button {font-size: 16px;line-height: 20px;min-height: auto;padding: 15px 35px;}
h2 {font-size: 50px;line-height: 66px;}
.sofia-p {font-size: 21px;line-height: 33px;}
.outfit-p{font-size: 18px;line-height: 26px;}
}
.blog-details-sec .post-details .image img,
.blog-details-sec .post-details .media iframe,.blog-details-sec .post-details p img{width:100%;height:auto;}
.blog-details-sec .post-details p a strong, .blog-details-sec .post-details p a ,.blog-details-sec .post-details p strong{
  /* color: #FD6F21; */
  font-weight: bold;
}
.blog-details-sec .post-details p a{font-weight: normal;text-decoration: underline;font-size:24px;line-height:30px;}
.blog-details-sec .post-details blockquote{padding:20px;background:#f2f2f2;border-left:4px solid #FD6F21;border-radius:0 10px 10px 0;}
.blog-details-sec .post-details blockquote p{margin:0;}
/* Custom Check Box */


.right-label-checkbox {min-width:20px;height:20px;display: block !important;position: relative !important;padding:0 0 0 30px;margin-bottom: 0 !important;cursor: pointer !important;font-size: 16px !important;line-height:20px !important;-webkit-user-select: none !important;-moz-user-select: none !important;-ms-user-select: none !important;user-select: none !important;}
.right-label-checkbox.no-label-text {padding:0;}
.right-label-checkbox a.themeLink{font-size:16px;line-height:20px;color:#FD6F21;}
.right-label-checkbox input {position: absolute !important;opacity: 0 !important;cursor: pointer !important;height: 0 !important;width: 0 !important;}
.right-label-checkbox .checkmark {position: absolute !important;top: 0 !important;left: 0 !important;height: 20px !important;width: 20px !important;border:1px solid #FD6F21 !important; background-color: transparent; border-radius:4px !important;}
.right-label-checkbox .checkmark:after {content: "" !important;position: absolute !important;display: none !important;}
.right-label-checkbox input:checked ~ .checkmark{background-color: #FD6F21;}
.right-label-checkbox input:checked ~ .checkmark:after {display: block !important;}
.right-label-checkbox .checkmark:after {left: 6px !important;top: 1px !important;width: 7px !important;height: 12px !important;border: solid #fff !important;border-width: 0 2px 2px 0 !important;-webkit-transform: rotate(45deg) !important;-ms-transform: rotate(45deg) !important;transform: rotate(45deg) !important;}





@media (max-width:1520px) {
  section{ padding: 60px 0px 0px 0px;}
  a{ font-size: 18px; line-height: 22px; }
  .outfit-p{ font-size: 16px; line-height: 20px; }
  .sofia-p{ font-size: 18px; line-height: 24px;}
  .blueLink{ margin-bottom: 8px; font-size: 14px; line-height: 18px;}
  .theme-btn, button{ font-size: 14px; line-height: 18px; border-radius: 5px; padding: 14px 25px;}
  .dbLogoutBtn{ min-height: 35px !important; min-width: 90px !important;}
  .black-btn{ min-width: 110px; }
  .modal .theme-btn{ min-width: 90px;}
  .modal .orange-btn {min-width: 70px;}
  .outline-btn{ margin-top: 50px;}
  .italicLink{ font-size: 18px; line-height: 22px;}
  h1{ font-size: 44px; line-height: 52px; margin-bottom: 18px;}
  h2, .sofia-h2{ font-size: 35px; line-height: 40px; margin-bottom: 18px; }
  .H2{ font-size: 25px; line-height: 29px; }
  .circle{ width: 18px; height: 18px; }
  .circle::before{ margin: 4px; }
  .link{font-size: 12px; line-height: 18px;}
  .graphItems ul li > div::before { width: 10px;margin-right: 6px;}
  .graphItems ul li:not(:last-child) { margin-right: 17px;}
  .graphItems ul li p { font-size: 15px;line-height: 18px;}
  /**/
  .modal .orange-btn, .video-modal .modal-footer .orange-btn {border-radius: 5px;}
}
@media (max-width:1399px) {
  ::-webkit-scrollbar, ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track { width: 5px;height: 5px;}
  section{ padding: 40px 0px 0px 0px;}
  .theme-btn, button, .orange-btn, .outline-btn{border-width: 1px;}
  a, .italicLink{ font-size: 16px; line-height: 20px; }
  .sofia-p{ font-size: 16px; line-height: 24px;}
  .outfit-p{ font-size: 14px; line-height: 19px; }
  .circle { width: 15px; height: 15px;}
  .notification-modal .notification-text, .notification-modal .notification-text a, .notification-modal .notification-date{font-size: 14px;line-height: 18px;}
  .search-results.non-active-user {
    right: 93px;
}
.view-invoice-table th, .view-invoice-table td{font-size: 16px;}
.video-modal .modal-content .animatedTab .videoModal { max-height: calc(100% - 128px);}
/**/
h1 {font-size: 42px;line-height: 48px;}
}
@media (max-width: 1199px) {
  .themeAnalytics .tabFlex > ul li:not(:last-child) { margin-right: 26px;}
  h1 {font-size: 31px;line-height: 41px;}
}
@media (max-width: 896px) {
.iconDD .dropdown-menu { transform: translate(-6px, 42px) !important;}
}
@media (max-width:776px) {
.tabFlex{flex-direction: column-reverse; padding: 10px; margin: 0px;}
.tabFlex > ul, .themeAnalytics .react-tabs__tab-list{width: 100%;}
.tabFlex > ul li, .themeAnalytics .react-tabs__tab-list li{margin-bottom:10px;}
.tabFlex .realtimeBox{width: 100%; display: flex; justify-content: center;}
.themeAnalytics .react-tabs__tab{margin: 0px 10px 10px 10px;}
.videoImg{width: 90px; height: 90px;}
.videoImg img{width:100%; height: 100%; object-fit: cover;}
}
@media (max-width: 767.98px){
  /* .explore.explore-sec .category-video-btn {margin-left: auto !important;
    margin-top: 10px !important;margin-right: 0;} */
    h1 {font-size: 27px;line-height: 37px;}
    .theme-btn, button {padding: 12px 25px;}
}
@media (max-width:575px) {
  section{ padding: 60px 0px 0px 0px;}
  .outfit-p{ font-size: 14px; line-height: 19px; }
  .sofia-p{ font-size: 16px; line-height: 22px;}
  .orange-btn, .outline-btn{ margin-top: 30px;}
  h1, h2,  .sofia-h2 { font-size: 28px; line-height: 34px; margin-bottom: 15px;}
  h1, h2 {line-height: 36px; }
  .H2{ font-size: 20px; line-height: 24px; }
  .go-tp-profile span{font-size: 16px;}
  .dashboard-sitePages h2{text-align: center;}
  .themeAnalytics .tabFlex > ul li:not(:last-child){margin-right: 0;margin-left: 0;}
  .themeAnalytics .tabFlex > ul{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  /**/
  .video-modal .video-dragger-holder > div > label {width: 70px;}
  .video-modal .video-dragger-holder > div > .icon-holder {width: 25px;}
  .video-modal .video-dragger-holder > div > input {width: calc(100% - 95px);}
  .video-modal .edit-delete-icons .icon-holder {width: 30px;height: 30px;font-size: 12px;}
  
}
@media (max-width: 479px) {
  .rc-anchor-normal {width: 199px;margin: 0 auto;}
  /**/
  .video-modal .updated-button-flex{flex-direction: column;}
  .video-modal .updated-span {margin-left: 0px;margin-top: 10px;}
  .video-modal .video-dragger-holder > div > label {width: 35px;font-size: 12px;}
  .video-modal .video-dragger-holder > div > .icon-holder {width: 15px;}
  .video-modal .video-dragger-holder > div > input {width: calc(100% - 65px);}
  .video-modal .modal-content .animatedTab .videoModal {max-height: calc(100% - 200px);}
  /**/
  .theme-btn, button {font-size: 12px;line-height: 16px;}
}


.submit-errors{
  font-size: 12px
}
.pointer{
  cursor: pointer;
}
.delete-all-icon {
  font-size: 22px !important;
}

.rc-pagination {display: flex;padding: 0;margin: 0;font-size: 14px;list-style: none;}
.rc-pagination-disabled, .rc-pagination-disabled:hover, .rc-pagination-disabled:focus {cursor: not-allowed;opacity: 0.5;}
.rc-pagination-prev, .rc-pagination-next {outline: 0;}
.rc-pagination-prev, .rc-pagination-next, .rc-pagination-jump-prev, .rc-pagination-jump-next {display: inline-block;min-width: 28px;height: 28px;color: rgba(0, 0, 0, 0.85);font-family: Arial;line-height: 28px;text-align: center;vertical-align: middle;list-style: none;border-radius: 2px;cursor: pointer;transition: all 0.3s;}
.rc-pagination-prev, .rc-pagination-jump-prev, .rc-pagination-jump-next {margin-right: 8px;}
.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link {min-height: unset; padding: 0; display: block;width: 100%;height: 100%;font-size: 12px;text-align: center;background-color: #fff;border: 1px solid #d9d9d9;border-radius: 2px;outline: none;transition: all 0.3s;}
.rc-pagination-prev button, .rc-pagination-next button {-webkit-user-select: none;user-select: none;}
.rc-pagination-prev button:after {content: "‹";display: block;}
.rc-pagination-next button:after {content: "›";display: block;}
.rc-pagination-item, .rc-pagination-total-text {display: inline-block;height: 28px;margin-right: 8px;line-height: 26px;vertical-align: middle;}
.rc-pagination-item {min-width: 28px;font-family: Arial;text-align: center;list-style: none;border-radius: 2px;outline: 0;cursor: pointer;-webkit-user-select: none;-ms-user-select: none;user-select: none;}
.rc-pagination-item-active {font-weight: 500;}
.rc-pagination-item{ background: transparent  !important;  border: 1px solid #FD6F21 !important; font-weight: 500; color: #FD6F21  !important;}
.rc-pagination-item-active { font-weight: 500; background: #FD6F21 !important; border: 1px solid #FD6F21 !important; color: #fff !important;}
.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link{ background: transparent  !important; border: 1px solid #FD6F21 !important; font-weight: 500; color: #FD6F21 !important; font-size: 25px !important; line-height: 10px;}
.rc-pagination-item:focus, .rc-pagination-item:hover {color: #fff !important;background: #FD6F21 !important;}
.rc-pagination-item-active:focus, .rc-pagination-item-active:hover{color: #fff !important;}
.rc-pagination-item-active:focus a, .rc-pagination-item-active:hover a{color: #fff !important;}

#webpack-dev-server-client-overlay {
  display: none !important;
}

/* .vjs-tech {
  pointer-events: none;
} */

.detected-elements {
  z-index: 1;
}
.vjs-control-bar {
  z-index: 2;
}

.Toastify__close-button { opacity: 1 }
.Toastify__close-button > svg { color: #fff }
.Toastify__close-button > svg:hover { color: #fd6f21 }

.selectedTabColor { background-color : #f79763 };
