.package-card .packageH3.font {
  font-size: 50px;
  line-height: 60px;
}

@media (max-width: 1699px) {
  .package-card .packageH3.font {
    font-size: 45px;
    line-height: 49px;
  }
}

@media (max-width: 1399px) {
  .package-card .packageH3.font {
    font-size: 40px;
    line-height: 45px;
  }
}

@media (max-width: 767px) {
  .package-card .packageH3.font {
    font-size: 35px;
    line-height: 40px;
  }
}

@media (max-width: 767px) {
    .package-card .packageH3.font {
      font-size: 27px;
      line-height: 32px;
    }
  }
